export const order = {
    namespaced: true,
    state: {
        showModal: false,
        data: {
            productId: '',
            product: '',
            serviceId: '',
            service: ''
        },
    },
    actions: {
        showModal({commit}, data) {
            commit('showModal', data)
        },
        hideModal({commit}) {
            commit('hideModal')
        }
    },
    mutations: {
        showModal(state, data) {
            if (data.isProduct) {
                state.data.productId = data.id
                state.data.product = data.name
            } else if (data.isService) {
                state.data.serviceId = data.id
                state.data.service = data.name
            }
            state.showModal = true
        },
        hideModal(state) {
            state.showModal = false
            state.data.productId = ''
            state.data.product = ''
            state.data.serviceId = ''
            state.data.service = ''
        },
    }
}
