<template>
  <v-app>
    <AppLoader :loading="loading"/>
    <v-main class="test">
      <AppNavbar :loading="loading" @openNavbar="mobileNavbar = true" @scrollToContacts="scrollToContacts()"/>
      <router-view :loading="loading" @scrollToContacts="scrollToContacts()"/>
      <PartContacts v-if="activeLink !== 'contacts'"/>
    </v-main>
    <v-footer class="pa-0">
      <AppFooter/>
    </v-footer>
    
<!--    <AppModal></AppModal>-->
    
    <AppNotify></AppNotify>
    
    <v-navigation-drawer
      v-model="mobileNavbar"
      location="bottom"
      temporary
      class="mobile__navbar"
    >
      <v-sheet
        class="text-center position-relative mobile__navbar__wrapper"
        height="100%" width="100vw"
      >
        <div class="mobile__navbar__logo">
          <img :src="require('@/assets/images/logo.svg')" alt="logo" @click="$router.push({name: 'home'}); mobileNavbar = false"/>
        </div>
        <v-icon
          class="position-absolute mobile__navbar__close"
          @click="mobileNavbar = !mobileNavbar"
        >mdi-close
        </v-icon>
        <div class="py-3">
          <v-list class="d-flex flex-column justify-center align-center" color="rgba(0,0,0,0)">
            <v-list-item v-for="link of links" :key="'link-mobile-' + link.to">
              <v-btn variant="text" class="mobile__navbar__link-item" :class="{'mobile__navbar__link-active': activeLink === link.to}" @click="$router.push({name: link.to, params: link.params}).catch(() => {}); mobileNavbar = false">{{ link.name }}</v-btn>
            </v-list-item>
          </v-list>
        </div>
        <div class="d-flex flex-column">
          <span class="mobile__navbar__phone" @click="openTel()"><img :src="require('@/assets/images/icons/phone--outline.svg')" alt="phone">+7-913-919-84-34</span>
          <button class="navbar__btn btn" @click="scrollToContacts()"><span>Связаться с нами</span></button>
        </div>
      </v-sheet>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import gsap from "gsap"
import ScrollMagic from "scrollmagic";
import 'debug.addIndicators'
import {TweenMax, TimelineMax} from "gsap"
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap"

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

import AppNavbar from "@/components/AppNavbar.vue"
import AppFooter from "@/components/AppFooter.vue"
import PartContacts from "@/components/PartContacts.vue"
import AppLoader from "@/components/AppLoader.vue"
import AppNotify from "@/components/AppNotify.vue";
// import AppModal from "@/components/AppModal.vue";


export default {
  name: 'App',
  components: {
    // AppModal,
    AppNotify, AppLoader, AppNavbar, PartContacts, AppFooter},
  
  data: () => ({
    loading: true,
    mobileNavbar: false,
    links: [
      {name: 'Главная', to: 'home'},
      {name: 'О нас', to: 'about'},
      {name: 'Продукция', to: 'products'},
      // {name: 'Портфолио', to: 'works', params: {page: '1'}},
      {name: 'Калькулятор', to: 'calc'},
      {name: 'Контакты', to: 'contacts'},
    ]
  }),
  
  computed: {
    activeLink() {
      return this.$route.name
    }
  },
  
  mounted() {
    this.initLoad()
  },
  
  created() {
    // setTimeout(() => {
    //   const recaptcha = this.$recaptchaInstance
    //   recaptcha.value.hideBadge()
    // }, 1000)
  },
  
  unmounted() {
  },
  
  methods: {
    initLoad() {
      this.loading = true
      this.disableScroll()
      let interval = setInterval(() => {
        if (document.readyState === "complete") {
          this.enableScroll()
          this.loading = false
          gsap.timeline()
          this.$store.dispatch('animations/setControllerHome', new ScrollMagic.Controller())
          clearInterval(interval)
        }
      }, 2000)
    },
    
    preventDefault(e) {
      e = e || window.event
      if (e.preventDefault)
        e.preventDefault()
      e.returnValue = false
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', this.preventDefault, false)
      document.addEventListener('wheel', this.preventDefault, {passive: false})
      document.addEventListener('scroll', this.preventDefault, {passive: false})
      document.querySelector('html').style.scrollbarWidth = 'none'
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false)
      document.removeEventListener('wheel', this.preventDefault, {passive: false})
      document.removeEventListener('scroll', this.preventDefault, {passive: false})
      document.querySelector('html').style.scrollbarWidth = 'unset'
    },
    
    openTel() {
      let number = '+7 (913) 919 84-34'
      let phone = number.split(' ').join('')
      // eslint-disable-next-line no-useless-escape
      phone = phone.replace(/\(|\)|\-/g, '')
      if (number.startsWith('8' || '7')) {
        phone = phone.split('')
        phone.splice(0, 1, '+7')
        phone = phone.join('')
      }
      window.location.href = 'tel:' + phone
    },
    
    scrollToContacts() {
      this.mobileNavbar = false
      let contactsBlock = document.querySelector('#footer')
      
      contactsBlock.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start'
      })
    }
  },
  
  watch: {
    mobileNavbar(newVal) {
      newVal ? this.disableScroll() : this.enableScroll()
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'ВОЛЬФ ТЕХНОЛОДЖИ';
      }
    },
  }
  
  
}
</script>

<style lang="sass">

body
  overflow-x: hidden

.row-full-height
  height: calc(100% + 24px) !important

.btn
  background-color: #ffffff
  border: 1px solid #DB0133
  border-radius: 2px
  padding: 10px 30px
  text-transform: unset
  color: #000000
  cursor: pointer
  background:
    image: linear-gradient(45deg, #DB0133 50%, transparent 50%)
    position: 100%
    size: 240%
  transition: background 800ms ease-in-out, color 800ms ease-in-out
  
  &:hover
    background-position: 0
    color: #ffffff


.text--red
  color: #DB0133

@media (max-width: 1263.99px)
  .mobile__navbar
    height: 100vh !important
    padding-top: 100px
    &__logo
      padding-top: 30px
      img
        height: 70px
        width: auto
        
    &__close
      right: 30px
      top: 30px
        
    &__phone
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 20px
      img
        height: 30px
        width: 30px
        margin-right: 5px
    
    &__link
      &-item
        text-transform: unset
      &-active
        color: #DB0133 !important
    
    &-contacts
      display: flex
      flex-direction: column
      
      &__phone
        .v-icon
        color: #52871A !important
      
      &__email
        text-transform: unset
        .v-icon
          color: #52871A !important
      
      &__social
        .v-btn
        text-transform: unset
        

@media (max-width: 959.99px)
  .btn
    background-color: #DB0133
    color: #ffffff !important
    cursor: pointer
    background-image: unset
    transition: unset
</style>
