import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProductView from "@/views/ProductView.vue";
import PortfolioView from "@/views/PortfolioView.vue";
import CalcView from "@/views/CalcView.vue";
import ContactsView from "@/views/ContactsView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'О компании | ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
  {
    path: '/products',
    name: 'products',
    component: ProductView,
    meta: { title: 'Продукция | ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
  {
    path: '/works',
    name: 'works',
    component: PortfolioView,
    meta: { title: 'Портфолио | ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
  {
    path: '/calc',
    name: 'calc',
    component: CalcView,
    meta: { title: 'Калькулятор | ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView,
    meta: { title: 'Контакты | ВОЛЬФ ТЕХНОЛОДЖИ' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
