<template>
  <div>
    <v-container fluid class="contacts__map__container pa-0">
      <div class="contacts__map__wrap">
        <a href="https://yandex.ru/maps/org/volf_tekhnolodzhi/125419001112/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Вольф Технолоджи</a>
        <a href="https://yandex.ru/maps/11316/novosibirsk-oblast/category/metalworking_equipment/184106660/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Металлообрабатывающее оборудование в Новосибирской области</a>
        <a href="https://yandex.ru/maps/11316/novosibirsk-oblast/category/metalwork/184106662/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:28px;">Металлообработка в Новосибирской области</a>
        <iframe src="https://yandex.ru/map-widget/v1/?ll=82.997780%2C54.920992&mode=search&oid=125419001112&ol=biz&utm_source=main_stripe_big&z=18.83" width="100%" height="100%" allowfullscreen="true" style="position:relative; border: none"></iframe>
      </div>
    </v-container>
    
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="5" offset-md="1">
          <h1 class="contacts__title">Наши <span class="text--red">контакты</span></h1>
          <div class="contacts__items">
            <div class="contacts__items">
              <div class="contacts__items__address" @click="openMap()">
                <div>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </div>
                <span>г.Новосибирск, ул.Восточная 4/1</span>
              </div>
              <div class="contacts__items__phone" @click="openTel()">
                <div>
                  <img :src="require('@/assets/images/icons/phone--outline.svg')" alt="phone">
                </div>
                <span>+7-913-919-84-34</span>
              </div>
              <div class="contacts__items__email" @click="openEmail('all@wolf-tools.ru')">
                <div>
                  <v-icon>mdi-email-outline</v-icon>
                </div>
                <span>all@wolf-tools.ru</span>
              </div>
              <div class="contacts__items__instagram" @click="openInst()">
                <div>
                  <img :src="require('@/assets/images/icons/instagram.svg')" alt="instagram">
                </div>
                <span>_wolf_technology_</span>
              </div>
            </div>
          </div>
        </v-col>
        
<!--        <v-col cols="12" sm="6" class="position-relative">-->
<!--          <div class="contacts__line d-none d-lg-block">-->
<!--            <v-img :src="require('@/assets/images/contacts/line.svg')"></v-img>-->
<!--          </div>-->
<!--          -->
<!--          <AppForm></AppForm>-->
<!--        </v-col>-->
      </v-row>
    </v-container>
  </div>
</template>

<script>

// Components
// import AppForm from "@/components/AppForm.vue";

export default {
  name: 'CalcView',
  
  data: () => ({}),
  
  components: {
    // AppForm
  },
  
  methods: {
    openTel() {
      let number = '+7 (913) 919 84-34'
      let phone = number.split(' ').join('')
      // eslint-disable-next-line no-useless-escape
      phone = phone.replace(/\(|\)|\-/g, '')
      if (number.startsWith('8' || '7')) {
        phone = phone.split('')
        phone.splice(0, 1, '+7')
        phone = phone.join('')
      }
      window.location.href = 'tel:' + phone
    },
    
    openEmail(email) {
      window.location.href = `mailto:${email}`
    },
    
    openInst() {
      window.open(`https://instagram.com/_wolf_technology_?utm_medium=copy_link`, '_blank');
    },
    
    openMap() {
      window.open(`https://yandex.ru/maps/org/volf_tekhnolodzhi/125419001112/?from=mapframe&ll=82.997721%2C54.921029&z=17`, '_blank');
    }
  }
}
</script>

<style lang="sass">

.contacts
  &__map
    &__wrap
      position: relative
      height: 700px
      z-index: 1
  
  &__line
    position: absolute
    top: -94px
    right: -126px
    bottom: -21px
    left: -169px
    z-index: 0
  
  &__title
    margin-top: 48px
    line-height: 100%
    font-size: 42px
    text-transform: uppercase
  
  &__items
    margin-top: 20px
    display: flex
    flex-direction: column
    align-items: flex-start
    font-size: 28px
    
    span
      line-height: 100%
    
    &__address
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 60px
        width: 42px
        margin-right: 8px
    
    &__phone
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 60px
        width: 42px
        margin-right: 5px
      
      img
        width: 100%
        height: auto
    
    &__email
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 60px
        width: 42px
        margin-right: 5px
    
    &__instagram
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 60px
        width: 42px
        margin-right: 5px
      
      img
        width: 100%
        height: auto

@media (max-width: 2559.98px)
  
  .contacts
    &__map
      &__wrap
        height: 600px
    
    &__line
      top: -12px
    
    &__title
      margin-top: 20px
      font-size: 38px
    
    &__items
      margin-top: 20px
      display: flex
      flex-direction: column
      align-items: flex-start
      font-size: 24px
      
      &__address
        div
          height: 50px
          width: 36px
      
      &__phone
        div
          height: 50px
          width: 36px
      
      &__email
        div
          height: 50px
          width: 36px
      
      &__instagram
        div
          height: 50px
          width: 36px

@media (max-width: 1919.98px)
  
  .contacts
    &__map
      &__wrap
        height: 500px
    
    &__line
      position: absolute
      top: -3px
      right: -485px
      bottom: -21px
      left: -45px
      z-index: 0
    
    &__title
      font-size: 30px
    
    &__items
      margin-top: 20px
      display: flex
      flex-direction: column
      align-items: flex-start
      font-size: 20px
      
      &__address
        div
          height: 40px
          width: 30px
      
      &__phone
        div
          height: 40px
          width: 30px
      
      &__email
        div
          height: 40px
          width: 30px
      
      &__instagram
        div
          height: 40px
          width: 30px

@media (max-width: 1259.98px)
  
  .contacts
    &__map
      &__wrap
        height: 400px
    
    &__title
      font-size: 28px
    
    &__items
      margin-top: 16px
      font-size: 18px
      
      &__address
        div
          height: 40px
          width: 27px
      
      &__phone
        div
          height: 40px
          width: 27px
      
      &__email
        div
          height: 40px
          width: 27px
      
      &__instagram
        div
          height: 40px
          width: 27px

@media (max-width: 959.98px)
  
  .contacts
    &__map
      &__wrap
        height: 400px
    
    &__title
      font-size: 26px
    
    &__items
      margin-top: 16px
      font-size: 16px
      
      &__address
        div
          height: 40px
          width: 24px
      
      &__phone
        div
          height: 40px
          width: 24px
      
      &__email
        div
          height: 40px
          width: 24px
      
      &__instagram
        div
          height: 40px
          width: 24px

@media (max-width: 599.98px)
  
  .contacts
    &__map
      &__wrap
        height: 400px
    
    &__title
      font-size: 22px
    
    &__items
      margin-top: 16px
      font-size: 18px
      
      &__address
        div
          height: 40px
          width: 27px
      
      &__phone
        div
          height: 40px
          width: 27px
      
      &__email
        div
          height: 40px
          width: 27px
      
      &__instagram
        div
          height: 40px
          width: 27px

</style>
