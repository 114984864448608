<template>
  <v-card class="product__card">
    <div class="product__card__wrapper">
      <div class="product__info" v-if="product.name">
        <h3 class="product__title">{{ product.name }}</h3>
        <p class="product__description mb-0" v-html="product.text"></p>
        
        <img class="d-block d-xxl-none mt-3" v-if="table" :src="require(`@/assets/images/products/${product.imageX}`)" alt="image">
<!--        <div class="product__btns">-->
<!--          <button class="product__btn btn" @click="showModal()">Заказать</button>-->
<!--          &lt;!&ndash;          <button class="product__btn btn">Подробнее</button>&ndash;&gt;-->
<!--        </div>-->
        <template v-for="table of product.tables" :key="'table-' + table.id">
          <span class="product__table__title">{{ table.title }}</span>
          <v-table class="product__table" height="100%" fixed-header density="compact" hover="true">
            <thead>
              <tr>
                <th class="text-center" v-for="i of table.head" :key="i">
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="value in table.values" :key="value.cipher">
                <td class="text-no-wrap">{{ value.cipher }}</td>
                <td class="text-center text-no-wrap" v-if="value.d">{{ value.d }}</td>
                <td class="text-center text-no-wrap" v-if="value.d1">{{ value.d1 }}</td>
                <td class="text-center text-no-wrap" v-if="value.l">{{ value.l }}</td>
                <td class="text-center text-no-wrap" v-if="value.L">{{ value.L }}</td>
                <td class="text-center text-no-wrap" v-if="value.C">{{ value.C }}</td>
                <td class="text-center text-no-wrap" v-if="value.R">{{ value.R }}</td>
              </tr>
            </tbody>
          </v-table>
        </template>
      </div>
      <div class="product__img" :class="{'d-none d-xxl-block': table}">
        <img :src="require(`@/assets/images/products/${product.image}`)" alt="productImage">
      </div>
    </div>
    <div class="product__card__line"></div>
  </v-card>
</template>

<script>

export default {
  name: 'EmptyTemplate',
  
  components: {},
  
  props: ['loading', 'product', 'table'],
  
  data: () => ({}),
  
  methods: {
    showModal() {
      this.$store.dispatch('order/showModal', {isProduct: true, id: this.product.id, name: this.product.name})
    }
  }
}

</script>


<style lang="sass">

.product
  &__card
    border-radius: 4px
    background: #F4F4F4
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15)
    height: 100%
    
    &__wrapper
      position: relative
      display: flex
      height: 100%
    
    &__line
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 2px
      background-color: red
  
  &__img
    width: 30%
    flex: 1 0 auto
    
    img
      height: 100%
      width: 100%
      object-fit: cover
  
  
  &__info
    display: flex
    flex-direction: column
    padding: 60px 24px 60px 60px
    max-width: 70%
    
  &__table
    &__title
      margin-top: 16px
      margin-bottom: 8px
      font-weight: bold
  
  &__title
    font-size: 24px
  
  &__description
    margin-top: 10px
    margin-bottom: 16px
  
  &__btns
    margin-top: auto
  
  &__btn
    &:first-child
      margin-right: 30px

@media (max-width: 2559.98px)
  
  .product
    
    &__info
      padding: 40px 24px 40px 40px
      max-width: 100%
      width: 100%
    
    &__title
      font-size: 22px
    
    &__description
      font-size: 16px
      margin-top: 5px
    
    &__btn
      font-size: 14px

@media (max-width: 1919.98px)
  
  .product
    
    &__title
      font-size: 18px
    
    &__description
      font-size: 14px
    
    &__btn
      font-size: 12px

@media (max-width: 1279.98px)
  
  .product
    
    &__info
      padding: 18px 24px 18px 18px
    
    &__title
      font-size: 16px
    
    &__description
      font-size: 12px
    
    &__btn
      margin-top: auto
      font-size: 12px

@media (max-width: 959.98px)
  
  .product
    &__card
      &__wrapper
        flex-direction: column
    
    &__info
      order: 2
      max-width: 100%
    
    &__title
      font-size: 18px
    
    &__description
      font-size: 14px
    
    &__img
      width: 100%
      flex: 1 0 auto
      order: 1
      
      img
        height: 100%
        width: 100%
        object-fit: cover
</style>