<template>
  <v-app-bar class="navbar" app absolute flat color="#ffffff">
    <v-container class="h-100 py-0">
      <v-row class="h-100 my-0">
        <v-col cols="4" md="3" lg="2" class="d-flex position-relative h-100 py-0 px-lg-3">
          <div class="navbar__logo">
            <v-img @click="$router.push({name: 'home'}).catch(() => {})"
                   :src="require('@/assets/images/logo.svg')" class="navbar__logo__image" contain
            ></v-img>
          </div>
        </v-col>
        <v-col cols="7" lg="6" xl="5" class="d-none d-lg-flex py-0 pl-0">
          <div class="navbar__links-wrap">
            <template v-for="link of links" :key="'link-' + link.to">
              <div class="navbar__link-wrap">
                <span
                  @click="$router.push({name: link.to, params: link.params}).catch(() => {})"
                  class="navbar__link"
                >{{ link.name }}</span>
                <hr class="navbar__link--active" v-if="activeLink === link.to">
              </div>
            </template>
          </div>
        </v-col>
        <v-col cols="8" md="9" lg="4" xl="5" class="d-flex py-0">
          <div class="navbar__contacts-wrap">
            <span class="navbar__phone" @click="openTel()"><v-img :src="require('@/assets/images/icons/phone--outline.svg')" class="navbar__phone__icon"></v-img>+7-913-919-84-34</span>
            
            <button class="navbar__btn btn d-none d-lg-block" @click="$emit('scrollToContacts')"><span>Связаться с нами</span></button>
            
            <div class="navbar-mobile__icon align-center ml-sm-2 d-flex d-lg-none">
              <v-app-bar-nav-icon @click="$emit('openNavbar')"></v-app-bar-nav-icon>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>

import {TimelineMax} from "gsap"

export default {
  name: 'AppNavbar',
  props: ['loading'],
  components: {},
  
  data: () => ({
    mobileNavbar: false,
    links: [
      {name: 'Главная', to: 'home'},
      {name: 'О нас', to: 'about'},
      {name: 'Продукция', to: 'products'},
      // {name: 'Портфолио', to: 'works', params: {page: '1'}},
      {name: 'Калькулятор', to: 'calc'},
      {name: 'Контакты', to: 'contacts'},
    ]
  }),
  
  mounted() {
    let checkLoading = setInterval(() => {
      if (!this.loading) {
        clearInterval(checkLoading)
        setTimeout(() => {
          this.startNavbarAnimation()
        }, 900)
      }
    }, 100)
  },
  
  computed: {
    activeLink() {
      return this.$route.name
    }
  },
  
  created() {
  
  },
  
  methods: {
    startNavbarAnimation() {
      const navbarAnimation = new TimelineMax()
      
      navbarAnimation.from('.navbar__logo', 1.2, {
        opacity: 0,
        y: -120,
      })
      
      navbarAnimation.from('.navbar__link-wrap, .navbar__phone', 1.4, {
        opacity: 0,
        y: -120,
        stagger: 0.2
      }, '<')
      
      navbarAnimation.from('.navbar__btn', 1.2, {
        width: 0
      }, '<')
      
      navbarAnimation.from('.navbar__btn > span', 1.2, {
        display: 'none',
        opacity: 0
      }, 1.2)
      
      this.$store.state.animations.timelines.navbarAnimation = navbarAnimation
    },
    
    openTel() {
      let number = '+7 (913) 919 84-34'
      let phone = number.split(' ').join('')
      // eslint-disable-next-line no-useless-escape
      phone = phone.replace(/\(|\)|\-/g, '')
      if (number.startsWith('8' || '7')) {
        phone = phone.split('')
        phone.splice(0, 1, '+7')
        phone = phone.join('')
      }
      window.location.href = 'tel:' + phone
    },
  }
}
</script>

<style lang="sass">
.navbar
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.20) !important
  z-index: 500 !important
  
  &__logo
    height: 50px
    width: 165px
    margin-top: auto
    margin-bottom: auto
    cursor: pointer
  
  &__links-wrap
    display: flex
    justify-content: space-between
    align-self: center
    width: 100%
  
  &__link
    cursor: pointer
    transition: 0.2s
    
    &--active
      width: 100%
      color: red
    
    &:hover
      color: red
  
  &__contacts-wrap
    display: flex
    justify-content: flex-end
    width: 100%
  
  &__phone
    display: flex
    align-self: center
    margin-right: 160px
    cursor: pointer
    
    &__icon
      width: 20px
      height: 20px
      margin-top: 2px
      margin-right: 5px
  
  &__btn
    align-self: center
    width: 196px
    height: 44px

@media (max-width: 2559.98px)

@media (max-width: 1919.98px)
  .navbar
    &__logo
      height: auto
      width: 140px
    
    &__link
      font-size: 14px
    
    &__phone
      margin-right: 20px
      font-size: 14px
      
      &__icon
        width: 18px
        height: 18px
        margin-top: 1px
        margin-right: 4px

</style>
