<template>
  <v-alert
      class="alert ju"
      v-model="notify.show"
      :color="color"
      border="left"
      colored-border
      transition="slide-x-reverse-transition"
      dismissible
  >{{notify.text}}
  </v-alert>
</template>

<script>
export default {
  name: 'AppNotify',
  computed: {
    notify() {
      return this.$store.state.notify.newNotify
    },
    color() {
      let color
      if (this.notify.type === 'success') {
        color = '#52871A'
      } else if (this.notify.type === 'error') {
        color = '#ff5252'
      }
      return color
    }
  }
}
</script>

<style scoped lang="sass">
.alert
  position: fixed
  right: 0
  bottom: 0
  width: auto
  max-width: 450px
  z-index: 100
  text-align: center

@media (max-width: 850.98px)
  .alert
    right: 0
    bottom: 42px
</style>
