<template>
  <div class="products">
    <v-container fluid class="products__header__container">
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <h1 class="products__header__title">Металлообрабатывающий инструмент <br> <span class="text--red">«ВОЛЬФ ТЕХНОЛОДЖИ»</span></h1>
          </v-col>
        </v-row>
        <!--        <div class="products__header__image">-->
        <!--          <v-img :src="require('@/assets/images/products/header__bg.png')"></v-img>-->
        <!--        </div>-->
      </v-container>
    </v-container>
    
<!--    <v-container>-->
<!--      <v-row>-->
<!--&lt;!&ndash;        <v-col cols="12">&ndash;&gt;-->
<!--&lt;!&ndash;          <h2 class="services__title">Наши <span class="text&#45;&#45;red">услуги</span></h2>&ndash;&gt;-->
<!--&lt;!&ndash;        </v-col>&ndash;&gt;-->
<!--        <v-col cols="12" sm="6">-->
<!--          <v-card class="services__card">-->
<!--            <h3 class="services__subtitle">Сверление</h3>-->
<!--            -->
<!--            <div class="services__text">-->
<!--              <p>-->
<!--                Стандартная программа сверления WOLF идеально подходит для типичных применений, таких как сверление отверстий под резьбу, сквозные отверстия, обработка деталей с отверстиями и т. д.-->
<!--              </p>-->
<!--              <br>-->
<!--              <ul>-->
<!--                Преимущества инструмента:-->
<!--                <li>Геометрия с высокими рабочими характеристиками.</li>-->
<!--                <li>Высокая скорость съема.</li>-->
<!--                <li>Покрытие TiAlN.</li>-->
<!--                <li>Высокая точность позиционирования.</li>-->
<!--                <li>Высокая концентрическая точность.</li>-->
<!--                <li>Специально подходит для жаропрочных сталей и суперсплавов.</li>-->
<!--                <li>Универсальное применение для сталей, инструментальных сталей, нержавеющих сталей, чугуна и алюминия.</li>-->
<!--                <li>Также подходит для титана и титановых сплавов.</li>-->
<!--                <li>Сверление глухих и сквозных отверстий.</li>-->
<!--                <li>Переточка в новом качестве инструмента.</li>-->
<!--              </ul>-->
<!--              <br>-->
<!--              <ul>-->
<!--                Характеристики инструмента:-->
<!--                <li>Инструмент в сочетании с специальной геометрией канавок обеспечивает мелкую стружку и идеальный отвод стружки.</li>-->
<!--                <li>Специальные твердосплавные заготовки обладают достаточной прочностью, чтобы минимизировать повреждения режущих кромок и способна обрабатывать закаленную сталь до 65 HRC.</li>-->
<!--                <li>Высочайшая производительность благодаря сквозной подаче СОЖ и покрытию TiAlN.</li>-->
<!--                <li>Покрытие TiAlN сводит к минимуму наросты на кромках и повышает износостойкость.</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--&lt;!&ndash;            <button class="services__btn btn" @click="showModal(0, 'Сверление')">Заказать</button>&ndash;&gt;-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="6">-->
<!--          <v-card class="services__card">-->
<!--            <h3 class="services__subtitle">Фрезерование</h3>-->
<!--            <div class="services__text">-->
<!--              <p>-->
<!--                Наш каталог инструментов высочайшего стандарта. WOLF предлагает очень широкий выбор высокопроизводительных концевых фрез для всех областей применения. Программа высококачественных высокопроизводительных концевых фрез практически для всех требований.-->
<!--              </p>-->
<!--              <br>-->
<!--              <ul>-->
<!--                Преимущества инструмента:-->
<!--                <li>Фрезерование штампов и штамповок.</li>-->
<!--                <li>Типовое фрезерование стандартных деталей.</li>-->
<!--                <li>Высокопроизводительные концевые фрезы WOLF позволяют экономично обрабатывать такие материалы, как нержавеющие материалы, титан и современные сплавы.</li>-->
<!--                <li>Высокопроизводительный фрезерный инструмент для динамического фрезерования с высокой скоростью удаления стружки.</li>-->
<!--                <li>Стандартный фрезерный инструмент для высокопроизводительного оборудования, выполнение сложных деталей, таких как пресс-формы и т.д.</li>-->
<!--              </ul>-->
<!--              <br>-->
<!--              <ul>-->
<!--                Тип:-->
<!--                <li>Обнижение (максимальная глубина резания).</li>-->
<!--                <li>Угловой радиус (защита кромки).</li>-->
<!--                <li>Резка по центру (сверление на небольшую глубину фрезой).</li>-->
<!--                <li>Большое пространство для канавок (хороший отвод стружки).</li>-->
<!--                <li>Особая геометрия (пониженная вибрация и высокая скорость удаления стружки).</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--&lt;!&ndash;            <button class="services__btn btn" @click="showModal(1, 'Фрезерование')">Заказать</button>&ndash;&gt;-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-container>-->
    
    <v-container>
      <v-row>
        <v-col cols="12">
          <div ref="productsTitle"></div>
          <h2 class="products__title">Наша <span class="text--red">продукция</span></h2>
        </v-col>
        <v-col cols="12" sm="6" v-for="product of productsWithTables" :key="'productWithTables-' + product.id">
          <ProductCard :product="product" :table="true"></ProductCard>
        </v-col>
        <v-col cols="6" sm="4" md="3" lg="3" xl="2" v-for="product of productsImages" :key="'productImages-' + product.id">
          <ProductCard :product="product"></ProductCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

// Components

import ProductCard from "@/components/ProductCard.vue"
import {mapState} from "vuex";

export default {
  name: 'ProductsView',
  
  components: {ProductCard},
  
  computed: {
    ...mapState({
      productsWithTables: state => state.products.withTables,
      productsImages: state => state.products.images,
    })
  },
  
  mounted() {
    if (this.$route.hash === '#products') {
      this.scrollToProducts()
    }
  },
  
  methods: {
    scrollToProducts() {
      setTimeout(() => {
        this.$refs.productsTitle.scrollIntoView({
          behavior: 'smooth'
        })
      }, 100)
    },
    showModal(id, name) {
      this.$store.dispatch('order/showModal', {isService: true, id, name})
    }
  }
}
</script>

<style lang="sass">

.products
  &__header
    &__container
      position: relative
      padding: 140px 0 !important
      //background-color: #f2f2f2
      //background-image: radial-gradient(circle at center,#f9f9f9 0%,#e2e2e2 100%)
      background-image: url("@/assets/images/products/products__bg.jpg")
      background-size: cover
    
    &__title
      line-height: 120%
      font-size: 46px
      text-transform: uppercase
      color: #ffffff
  
  //&__image
  //  position: absolute
  //  top: -110px
  //  right: 16px
  //  bottom: -140px
  //  width: 401px

  &__title
    margin-top: 60px
    line-height: 100%
    font-size: 42px
    text-transform: uppercase
    color: #000000

.services
  
  &__title
    margin-top: 60px
    line-height: 100%
    font-size: 42px
    text-transform: uppercase
    color: #000000
  
  &__card
    margin-top: 60px
    display: flex
    flex-direction: column
    height: 100%
    padding-top: 40px
    padding-bottom: 40px
  
  &__subtitle
    padding: 0 40px
    text-transform: uppercase
    font-size: 20px
    color: #DB0133
  
  &__text
    margin-top: 20px
    padding: 0 40px
    font-size: 20px
    
    li
      margin-left: 18px
  
  &__btn
    margin-top: auto
    width: 100%

@media (max-width: 2559.98px)
  .products
    &__header
      &__container
        padding: 120px 0 !important
      
      &__title
        font-size: 40px
    
    //&__image
    //  top: -90px
    //  right: 16px
    //  bottom: -120px
    //  width: 344px
    
    &__title
      margin-top: 36px
      font-size: 38px
  
  .services
    
    &__title
      margin-top: 36px
      font-size: 38px
    
    &__card
      padding-top: 30px
      padding-bottom: 30px
    
    &__subtitle
      padding: 0 30px
      text-transform: uppercase
      font-size: 20px
      color: #DB0133
    
    &__text
      margin-top: 20px
      padding: 0 30px
      font-size: 18px
      
      li
        margin-left: 18px
    
    &__btn
      margin-top: auto
      width: 100%

@media (max-width: 1919.98px)
  .products
    &__header
      &__container
        padding: 80px 0 !important
      
      &__title
        font-size: 32px
    
    &__title
      margin-top: 16px
      font-size: 30px
  
  .services
    
    &__title
      margin-top: 16px
      font-size: 30px
    
    &__subtitle
      padding: 0 30px
      text-transform: uppercase
      font-size: 18px
      color: #DB0133
    
    &__text
      margin-top: 20px
      padding: 0 30px
      font-size: 16px
      
      li
        margin-left: 18px
    
    &__btn
      margin-top: auto
      width: 100%

@media (max-width: 1259.98px)
  .products
    &__header
      &__container
        padding: 80px 0 !important
      
      &__title
        font-size: 30px
    
    &__title
      margin-top: 16px
      font-size: 30px
  
  .services
    
    &__title
      margin-top: 16px
      font-size: 28px
    
    &__card
      padding-top: 24px
      padding-bottom: 24px
    
    &__subtitle
      padding: 0 24px
      font-size: 18px
    
    &__text
      margin-top: 16px
      padding: 0 30px
      font-size: 16px
      
      li
        margin-left: 18px
    
    &__btn
      margin-top: auto
      width: 100%

@media (max-width: 959.98px)
  .products
    &__header
      &__container
        padding: 60px 0 !important
      
      &__title
        font-size: 28px
    
    &__title
      margin-top: 16px
      font-size: 24px
  
  .services
    
    &__title
      margin-top: 16px
      font-size: 24px
    
    &__card
      padding-top: 18px
      padding-bottom: 18px
    
    &__subtitle
      padding: 0 18px
      font-size: 18px
    
    &__text
      margin-top: 16px
      padding: 0 18px
      font-size: 16px
      
      li
        margin-left: 18px
    
    &__btn
      margin-top: auto
      width: 100%

@media (max-width: 599.98px)
  .products
    &__header
      &__container
        padding: 40px 0 !important
      
      &__title
        font-size: 26px
    
    &__title
      margin-top: 16px
      font-size: 20px
  
  .services
    
    &__title
      margin-top: 16px
      font-size: 20px
    
    &__card
      padding-top: 20px
      padding-bottom: 20px
    
    &__subtitle
      padding: 0 20px
      font-size: 18px
    
    &__text
      margin-top: 16px
      padding: 0 20px
      font-size: 16px
      
      li
        margin-left: 18px
    
    &__btn
      margin-top: auto
      width: 100%

</style>
