<template>
  <div>
    <v-container fluid class="about__header__container">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="about__header__title">О <span class="text--red">компании</span></h1>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    
    <v-container class="about__how-work__container">
      <!--      <div class="about__how-work__line__wrap">-->
      <!--        <v-img class="about__how-work__line d-none d-xxl-flex" :src="require('@/assets/images/about/line.svg')" cover></v-img>-->
      <!--        <v-img class="about__how-work__line d-none d-xl-flex d-xxl-none" :src="require('@/assets/images/about/line-xl.svg')" cover></v-img>-->
      <!--      </div>-->
      <v-row style="position: relative; z-index: 1">
        <v-col cols="12">
          <p class="about__how-work__text">
            Компания ООО «ВОЛЬФ ТЕХНОЛОДЖИ» создана в 2018 году группой специалистов, прошедших обучение в Европе и имеющих передовой опыт по проектированию и производству твердосплавного инструмента. Уже через год после этого было начато производство серийных партий цельных концевых фрез. Далее производство
            расширилось до производства серий радиусных фрез, производства инструмента по чертежам заказчика и восстановления режущих его свойств.
            <br>
            <br>
            Производственная площадка и склад «ВОЛЬФ ТЕХНОЛОДЖИ» находятся в промышленном центре Сибири – в Новосибирской области. И сегодня наша продукция отвечает самым высоким требованиям заказчиков в России и за рубежом, а скорость реагирования на запрос клиента и спектр сервисных услуг – сокращают ее внедрение в
            производственный процесс до дней и недель
          </p>
        </v-col>
        <!--        <v-col cols="4" lg="2" offset-xl="0" offset-lg="3">-->
        <!--          <div class="about__how-work__stats">-->
        <!--            <div class="about__how-work__stats__number"><span>100</span>%</div>-->
        <!--            <div class="about__how-work__stats__text">-->
        <!--              Lorem ipsum dolor sit amet consectetur adipiscing elit-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </v-col>-->
        <!--        <v-col cols="4" lg="2">-->
        <!--          <div class="about__how-work__stats">-->
        <!--            <div class="about__how-work__stats__number"><span>100</span>%</div>-->
        <!--            <div class="about__how-work__stats__text">-->
        <!--              Lorem ipsum dolor sit amet consectetur adipiscing elit-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </v-col>-->
        <!--        <v-col cols="4" lg="2">-->
        <!--          <div class="about__how-work__stats">-->
        <!--            <div class="about__how-work__stats__number"><span>100</span>%</div>-->
        <!--            <div class="about__how-work__stats__text">-->
        <!--              Lorem ipsum dolor sit amet consectetur adipiscing elit-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </v-col>-->
      </v-row>
    </v-container>
    
    <v-container fluid class="about__what-is__container">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="about__what-is__title">ПРИНЦИПЫ «ВОЛЬФ ТЕХНОЛОДЖИ»</h2>
          </v-col>
          
          <v-col cols="12">
            <div class="d-flex justify-space-between flex-wrap" style="column-gap: 15px; row-gap: 15px">
              <div class="about__what-is__elem">
                <div class="about__what-is__elem__image">
                  <v-img :src="require('@/assets/images/icons/quality--white.svg')" width="75px" height="75px"></v-img>
                </div>
                <div class="about__what-is__elem__title">Качество</div>
                <p class="about__what-is__elem__text">Контроль качества на всех этапах проектирования и производства инструмента, сырье от проверенных поставщиков и высокий потенциал специалистов.</p>
              </div>
              
              <div class="about__what-is__elem">
                <div class="about__what-is__elem__image">
                  <v-img :src="require('@/assets/images/icons/management--white.svg')" width="75px" height="75px"></v-img>
                </div>
                <div class="about__what-is__elem__title">Инновации</div>
                <p class="about__what-is__elem__text">Курс – на импортозамещение. Применение современных технологий производства.</p>
              </div>
              
              <div class="about__what-is__elem">
                <div class="about__what-is__elem__image">
                  <v-img :src="require('@/assets/images/icons/service--white.svg')" width="auto" height="75px"></v-img>
                </div>
                <div class="about__what-is__elem__title">Инжиниринг</div>
                <p class="about__what-is__elem__text">Передовой опыт и собственные уникальные разработки и подходы, как к производству, так и к восстановлению инструмента.</p>
              </div>
              
              <div class="about__what-is__elem">
                <div class="about__what-is__elem__image">
                  <v-img :src="require('@/assets/images/icons/support--white.svg')" width="75px" height="75px"></v-img>
                </div>
                <div class="about__what-is__elem__title">Сервис</div>
                <p class="about__what-is__elem__text">Диалог с клиентом и быстрота реагирования. Онлайн-консультации и выезд специалиста на ваше предприятие.</p>
              </div>
              
              <div class="about__what-is__elem">
                <div class="about__what-is__elem__image">
                  <v-img :src="require('@/assets/images/icons/convenience--white.svg')" width="auto" height="75px" style="margin-left: 6px"></v-img>
                </div>
                <div class="about__what-is__elem__title">Скорость поставок</div>
                <p class="about__what-is__elem__text">Поставка инструмента в кратчайшие сроки. Постоянный ассортимент готового инструмента диаметром от 2 до 20 мм на складе компании.</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    
    <v-container class="about__how-work__container">
      <v-row>
        <v-col cols="12">
          <p class="about__how-work__text">Наш инструмент успешно прошел испытания и применяется на крупных промышленных предприятиях России.</p>
        </v-col>
      </v-row>
    </v-container>
    
    <!--    <v-container class="about__history__container">-->
    <!--      <v-row>-->
    <!--        <v-col cols="12">-->
    <!--          <h2 class="about__history__title">Наша история</h2>-->
    <!--        </v-col>-->
    
    <!--        <v-col cols="12" md="7" lg="8" order="3" order-md="1">-->
    <!--          <p class="about__history__text">-->
    <!--            {{ slides[activeSlide].text }}-->
    <!--          </p>-->
    <!--        </v-col>-->
    <!--        <v-col cols="12" md="4" lg="3" order="2" order-md="2" class="position-relative">-->
    <!--          <div class="about__history__image__wrapper">-->
    <!--            <v-img class="about__history__image" :src="require(`@/assets/images/about/${slides[activeSlide].image}`)" cover></v-img>-->
    <!--          </div>-->
    <!--        </v-col>-->
    <!--        <v-col cols="12" md="1" order="1" order-md="3">-->
    <!--          <div class="about__history__years">-->
    <!--            <template v-for="i of slides" :key="'year-' + i.id">-->
    <!--              <hr class="d-none d-md-block">-->
    <!--              <span class="about__history__years__item" :class="{'about__history__years__item&#45;&#45;active': i.id === activeSlide}" @click="activeSlide = i.id">{{ i.year }}</span>-->
    <!--            </template>-->
    <!--            <hr class="d-none d-md-block">-->
    <!--          </div>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-container>-->
  </div>
</template>

<script>

// Components

import {CountUp} from "countup.js";

export default {
  name: 'AboutView',
  
  data: () => ({
    activeSlide: 0,
    // slides: [
    //     {
    //         id: 0,
    //         text: 'С основанием прецизионного шлифования был взят первый этап сегодняшней группы компаний WOLF. В результате образовавшаяся компания WOLF Werkzeugtechnologie GmbH сегодня предлагает вам полный спектр услуг, ориентированный на клиента, от переточки до нового производства во всех вариантах. С переездом в новое здание в 2008 году технология инструментальной оснастки WOLF была перенесена на современные производственные мощности, предлагающие оптимальные конструкции для условий и процессов, что привело к сокращению сроков поставки для наших клиентов.',
    //         year: '1985',
    //         image: 'history1985.webp'
    //     },
    //     {
    //         id: 1,
    //         text: 'В 1997 году мы переехали в наш современный центр нанесения покрытий и расширили спектр услуг группы WOLF за счет важной ключевой технологии. Поскольку с тех пор мы можем предложить как проверенные стандартные покрытия, так и индивидуально разработанные специальные покрытия, мы предлагаем нашим клиентам значительное расширение ассортимента продукции. С новым зданием в 2008 году мы смогли более чем вдвое увеличить производственные площади и, таким образом, расширить производственные мощности, процессы и структуры до высочайшего технологического уровня.',
    //         year: '1997',
    //         image: 'history1997.webp'
    //     },
    //     {
    //         id: 2,
    //         text: 'Благодаря современному оборудованию, производство пресс-форм и инструментов WOLF также предлагает самые высокие стандарты в области изготовления пресс-форм и инструментов. Таким образом, клиенты WOLF Group могут рассчитывать на универсальный сервис, уникальный в мировом масштабе.',
    //         year: '2000',
    //         image: 'history2000.webp'
    //     },
    //     {
    //         id: 3,
    //         text: 'Еще одним шагом в истории WOLF стала покупка американской компании Performedge. Благодаря этому приобретению мы также смогли присутствовать в США и еще больше расширить наши деловые отношения. С тех пор Performedge была переименована в WOLF Tool Technology USA.',
    //         year: '2005',
    //         image: 'history2005.webp'
    //     },
    //     {id: 4, text: 'Основание WOLF Holding GmbH & amp; Co. KG в качестве основного названия, под которым зарегистрированы компании группы WOLF.', year: '2006', image: 'history2006.webp'},
    //     {
    //         id: 5,
    //         text: 'Благодаря расширению площади на 6500 м² и строительству нового производственного цеха инструментальной техники был реализован последний шаг. Производственные площади были увеличены на 2750 м², в них интегрировано самое современное оборудование и технологии снабжения. Так что начните с нами и воспользуйтесь нашими расширенными возможностями и техническим прогрессом для ваших процессов.',
    //         year: '2008',
    //         image: 'history2008.webp'
    //     },
    //     {id: 6, text: 'В 2013 году группа WOLF учредила еще одну компанию в Бангалоре, Индия.', year: '2013', image: 'history2013.webp'},
    //     {id: 7, text: 'В 2014 году группа WOLF учредила еще одну компанию в Тайцанге, Китай.', year: '2014', image: 'history2014.webp'},
    //     {id: 8, text: 'The Bochum location, еще один центр нанесения покрытий WOLF', year: '2018', image: 'history2018.webp'},
    //     {id: 9, text: 'В 2019 WOLF Tool Technology учредил еще одну компанию в Краснообске, Россия.', year: '2019', image: 'history2019.webp'},
    // ]
  }),
  
  mounted() {
    document.querySelectorAll('.about__how-work__stats__number span').forEach((el) => {
      let countUp = new CountUp(el, 100, {enableScrollSpy: true, scrollSpyOnce: true})
      
      countUp.start()
    })
  },
  
  components: {},
}
</script>

<style lang="sass">

.about
  &__header
    &__container
      padding: 140px 0 !important
      background-image: url("@/assets/images/about/header__bg.png")
      background-size: cover
      background-position-x: center
    
    &__title
      margin-left: 46px
      line-height: 100%
      font-size: 46px
      text-transform: uppercase
      color: #ffffff
  
  &__how-work
    
    &__container
      position: relative
      //height: 645px
      height: auto
    
    &__line__wrap
      position: absolute
      top: 0
      right: 16px
      bottom: 0
      left: 16px
      z-index: 0
    
    &__title
      //margin-top: 80px
      margin-top: 16px
      margin-left: 58px
      text-transform: uppercase
      font-size: 24px
      color: #DB0133
    
    &__text
      margin: 16px 60px 16px 58px
      // ?
      font-size: 20px
    
    &__stats
      margin-top: 65px
      margin-left: 58px
      text-align: center
      
      &__number
        font-size: 48px
        font-weight: bold
        color: #DB0133
      
      &__text
        font-size: 18px
  
  &__what-is
    &__container
      padding-top: 70px
      padding-bottom: 70px
      background-color: #3B3B3B
    
    &__title
      margin-bottom: 20px
      text-align: center
      line-height: 100%
      font-size: 42px
      text-transform: uppercase
      color: #ffffff
    
    &__elem
      display: flex
      flex-direction: column
      align-items: center
      width: 440px
      
      &__image
        display: flex
        align-items: center
        width: 160px
        height: 160px
        border-radius: 100%
        background-color: #DB0133
      
      &__title
        margin-top: 16px
        font-size: 20px
        text-transform: uppercase
        color: #ffffff
      
      &__text
        margin-top: 16px
        text-align: center
        color: #ffffff
  
  &__history
    
    &__title
      margin-top: 72px
      line-height: 100%
      font-size: 42px
      text-transform: uppercase
    
    &__text
      font-size: 20px
    
    &__image
      
      &__wrapper
    
    &__years
      display: flex
      flex-direction: column
      justify-content: space-between
      height: 100%
      
      &__item
        text-align: center
        font-size: 34px
        cursor: pointer
        
        &--active
          font-weight: bold
          background: linear-gradient(180deg, #3B3B3B 50%, #F00 50%, #DB0133 50.01%)
          background-clip: text
          -webkit-background-clip: text
          -webkit-text-fill-color: transparent


@media (max-width: 2559.98px)
  .about
    &__header
      &__container
        padding: 120px 0 !important
      
      &__title
        font-size: 40px
    
    &__how-work
      &__container
      //padding-bottom: 48px
      //height: 530px

      &__line__wrap
        position: absolute
        top: 0
        right: 16px
        bottom: 0
        left: 16px
        z-index: 0
      
      &__title
        //margin-top: 50px
        margin-left: 46px
        font-size: 20px
      
      &__text
        //margin-top: 14px
        margin-right: 60px
        margin-left: 46px
        font-size: 18px
      
      &__stats
        margin-top: 35px
        margin-left: 46px
        
        &__number
          font-size: 40px
        
        &__text
          font-size: 16px
    
    &__what-is
      &__container
        padding-top: 50px
        padding-bottom: 50px
      
      &__title
        margin-bottom: 20px
        font-size: 38px
      
      &__elem
        width: 340px
        
        &__image
          width: 140px
          height: 140px
        
        &__title
          margin-top: 14px
          font-size: 18px
    
    &__history
      
      &__title
        margin-top: 36px
        font-size: 38px
      
      &__text
        font-size: 18px
      
      &__line
        display: none
      
      &__image
        height: 100%
        
        &__wrapper
          height: 100%
      
      &__years
        
        &__item
          font-size: 24px

@media (max-width: 1919.98px)
  .about
    &__header
      &__container
        padding: 80px 0 !important
      
      &__title
        font-size: 32px
    
    &__how-work
      &__container
        padding-bottom: 30px
        height: auto
      
      &__title
        margin-top: 16px
        margin-left: 46px
        font-size: 24px
      
      &__text
        margin-top: 0
        margin-right: 60px
        margin-left: 46px
        font-size: 16px
      
      &__stats
        margin-top: 0
        margin-left: 0
        
        &__number
          font-size: 36px
        
        &__text
          font-size: 16px
    
    &__what-is
      &__container
        padding-top: 30px
        padding-bottom: 30px
      
      &__title
        margin-bottom: 20px
        font-size: 30px
      
      &__elem
        width: 220px
        
        &__image
          width: 100px
          height: 100px
          justify-content: center
          
          .v-img
            width: 60px !important
            max-width: 60px !important
            height: auto !important
        
        &__title
          margin-top: 14px
          font-size: 16px
        
        &__text
          margin-top: 8px
          font-size: 14px
    
    &__history
      
      &__title
        margin-top: 16px
        font-size: 30px
      
      &__text
        font-size: 16px
      
      &__line
        display: none
      
      &__image
        height: 100%
        
        &__wrapper
          height: 100%
      
      &__years
        
        &__item
          font-size: 20px

@media (max-width: 1259.98px)
  .about
    &__header
      &__container
        padding: 80px 0 !important
      
      &__title
        margin-left: 30px
        font-size: 30px
    
    &__how-work
      &__container
        padding-bottom: 30px
      
      &__title
        margin-left: 30px
        font-size: 20px
      
      &__text
        margin-top: 0
        margin-right: 30px
        margin-left: 30px
        font-size: 16px
      
      &__stats
        margin-top: 0
        margin-left: 0
        
        &__number
          font-size: 30px
        
        &__text
          font-size: 14px
    
    &__what-is
      &__container
        padding-top: 30px
        padding-bottom: 30px
      
      &__title
        margin-bottom: 20px
        font-size: 28px
      
      &__elem
        width: 279px
        
        &:nth-child(4), &:nth-child(5)
          width: calc(50% - 7.5px)
          
        &__image
          width: 100px
          height: 100px
          justify-content: center
          
          .v-img
            width: 60px !important
            max-width: 60px !important
            height: auto !important
        
        &__title
          margin-top: 14px
          font-size: 16px
        
        &__text
          margin-top: 8px
          font-size: 13px
    
    &__history
      
      &__title
        margin-top: 16px
        font-size: 28px
      
      &__text
        font-size: 16px
      
      &__line
        display: none
      
      &__image
        height: 100%
        
        &__wrapper
          height: 100%
      
      &__years
        
        &__item
          font-size: 20px

@media (max-width: 959.98px)
  .about
    &__header
      &__container
        padding: 60px 0 !important
      
      &__title
        margin-left: 20px
        font-size: 28px
    
    &__how-work
      &__container
        padding-bottom: 30px
      
      &__title
        margin-left: 20px
        font-size: 18px
      
      &__text
        margin-top: 0
        margin-right: 20px
        margin-left: 20px
        font-size: 16px
      
      &__stats
        margin-top: 0
        margin-left: 0
        
        &__number
          font-size: 30px
        
        &__text
          font-size: 12px
    
    &__what-is
      &__container
        padding-top: 20px
        padding-bottom: 20px
      
      &__title
        margin-bottom: 12px
        font-size: 26px
      
      &__elem
        &:nth-child(1), &:nth-child(2), &:nth-child(3)
          width: calc(33% - 10px)
        &:nth-child(4), &:nth-child(5)
          width: calc(50% - 7.5px)
          
        &__image
          width: 80px
          height: 80px
          justify-content: center
          
          .v-img
            width: 50px !important
            max-width: 50px !important
            height: auto !important
        
        &__title
          margin-top: 14px
          font-size: 14px
        
        &__text
          margin-top: 8px
          font-size: 12px
    
    &__history
      
      &__title
        margin-top: 16px
        font-size: 26px
      
      &__text
        font-size: 16px
      
      &__line
        display: none
      
      &__image
        height: 100%
        
        &__wrapper
          height: 100%
      
      &__years
        
        &__item
          font-size: 20px
      
      &__years
        display: flex
        flex-direction: row
        justify-content: space-between
        flex-wrap: wrap
        grid-gap: 10px
        height: 100%
        
        &__item
          text-align: center
          font-size: 20px
          cursor: pointer

@media (max-width: 599.98px)
  .about
    &__header
      &__container
        padding: 40px 0 !important
        background-position-x: -400px
      
      &__title
        margin-left: 0
        font-size: 26px
    
    &__how-work
      &__container
        padding-bottom: 30px
      
      &__title
        margin-left: 0
        font-size: 18px
      
      &__text
        margin-top: 0
        margin-right: 0
        margin-left: 0
        font-size: 16px
      
      &__stats
        margin-top: 0
        margin-left: 0
        
        &__number
          font-size: 24px
        
        &__text
          font-size: 13px
    
    &__what-is
      &__container
        padding-top: 20px
        padding-bottom: 20px
      
      &__title
        margin-bottom: 12px
        font-size: 20px
      
      &__elem
        &:nth-child(1), &:nth-child(2)
          width: calc(50% - 7.5px)
        &:nth-child(3), &:nth-child(4)
          width: calc(50% - 7.5px)
        &:nth-child(5)
          width: calc(100%)
          
        &__image
          width: 60px
          height: 60px
          justify-content: center
          
          .v-img
            width: 35px !important
            max-width: 35px !important
            height: auto !important
        
        &__title
          margin-top: 14px
          font-size: 14px
        
        &__text
          margin-top: 8px
          font-size: 12px
    
    &__history
      
      &__title
        margin-top: 16px
        font-size: 20px
      
      &__text
        font-size: 16px
      
      &__line
        display: none
      
      &__image
        height: 100%
        
        &__wrapper
          height: 100%
      
      &__years
        display: flex
        flex-direction: row
        justify-content: space-between
        flex-wrap: wrap
        grid-gap: 0
        height: 100%
        
        &__item
          width: 20%

</style>
