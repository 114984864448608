export const products = {
    namespaced: true,
    state: {
        withTables: [
            {
                id: 0,
                name: 'Фрезы универсальные. Серия 2350 MU4',
                text: 'Серия 2350 MU4 – Серия предназначена для обработки материалов группы P по ISO.<br><span style="font-weight: bold">4-х зубые концевые фрезы</span>',
                tables: [
                    {
                        id: 0,
                        title: 'Средняя серия',
                        head: ['Шифр', 'D', 'D1', 'l', 'L', 'C'],
                        values: [
                            { cipher: 'MU4 3/4-6-55', d: '3', d1: '4', l: '6', L: '55', C: '-' },
                            { cipher: 'MU4 4-10-55', d: '4', d1: '4', l: '10', L: '55', C: '-' },
                            { cipher: 'MU4 5C0.1/6-11-60', d: '5', d1: '6', l: '11', L: '60', C: '0.1' },
                            { cipher: 'MU4 6C0.2-15-60', d: '6', d1: '6', l: '15', L: '60', C: '0.2' },
                            { cipher: 'MU4 8C0.2-18-70', d: '8', d1: '8', l: '18', L: '70', C: '0.2' },
                            { cipher: 'MU4 10C0.2-22-75', d: '10', d1: '10', l: '22', L: '75', C: '0.2' },
                            { cipher: 'MU4 12C0.3-26-85', d: '12', d1: '12', l: '26', L: '85', C: '0.3' },
                            { cipher: 'MU4 14C0.3-28-90', d: '14', d1: '14', l: '28', L: '90', C: '0.3' },
                            { cipher: 'MU4 16C0.3-33-95', d: '16', d1: '16', l: '33', L: '95', C: '0.3' },
                            { cipher: 'MU4 18C0.3-33-95', d: '18', d1: '18', l: '33', L: '95', C: '0.3' },
                            { cipher: 'MU4 20C0.4-45-105', d: '20', d1: '20', l: '45', L: '105', C: '0.4' }
                        ]
                    },
                    {
                        id: 1,
                        title: 'Длинная серия',
                        head: ['Шифр', 'D', 'D1', 'l', 'L', 'C'],
                        values: [
                            { cipher: 'MU4 3/4-15-75', d: '3', d1: '4', l: '15', L: '75', C: '-' },
                            { cipher: 'MU4 4-20-75', d: '4', d1: '4', l: '20', L: '75', C: '-' },
                            { cipher: 'MU4 5C0.1/6-25-75', d: '5', d1: '6', l: '25', L: '75', C: '0.1' },
                            { cipher: 'MU4 6C0.2-45-100', d: '6', d1: '6', l: '45', L: '100', C: '0.2' },
                            { cipher: 'MU4 8C0.2-45-100', d: '8', d1: '8', l: '45', L: '100', C: '0.2' },
                            { cipher: 'MU4 10C0.2-45-100', d: '10', d1: '10', l: '45', L: '100', C: '0.2' },
                            { cipher: 'MU4 12C0.3-45-100', d: '12', d1: '12', l: '45', L: '100', C: '0.3' },
                            { cipher: 'MU4 14C0.3-45-100', d: '14', d1: '14', l: '45', L: '100', C: '0.3' },
                            { cipher: 'MU4 16C0.3-60-110', d: '16', d1: '16', l: '60', L: '125', C: '0.3' },
                            { cipher: 'MU4 18C0.3-60-125', d: '18', d1: '18', l: '60', L: '125', C: '0.3' },
                            { cipher: 'MU4 20C0.4-60-105', d: '20', d1: '20', l: '60', L: '125', C: '0.4' },
                        ]
                    },
                ],
                image: '2350_MU4.png',
                imageX: '2350_MU4.jpg',
            },
            {
                id: 1,
                name: 'Фрезы по аллюминию. Серия MN2',
                text: 'Серия MN2 – Серия предназначена для обработки материалов группы N по ISO.<br><span style="font-weight: bold">2-х зубые радиусные фрезы</span>',
                tables: [
                    {
                        id: 3,
                        title: 'Средняя серия',
                        head: ['Шифр', 'D', 'D1', 'l', 'L', 'R'],
                        values: [
                            { cipher: 'MN2 3R1.5/4-6-55', d: '3', d1: '4', l: '6', L: '55', R: '1.5' },
                            { cipher: 'MN2 4R2.0-10-55', d: '4', d1: '4', l: '10', L: '55', R: '2.0' },
                            { cipher: 'MN2 5R2.5/6-11-60', d: '5', d1: '6', l: '11', L: '60', R: '2.5' },
                            { cipher: 'MN2 6R3.0-15-60', d: '6', d1: '6', l: '15', L: '60', R: '3.0' },
                            { cipher: 'MN2 8R4.0-18-70', d: '8', d1: '8', l: '18', L: '70', R: '4.0' },
                            { cipher: 'MN2 10R5.0-22-75', d: '10', d1: '10', l: '22', L: '75', R: '5.0' },
                            { cipher: 'MN2 12R6.0-26-85', d: '12', d1: '12', l: '26', L: '85', R: '6.0' },
                            { cipher: 'MN2 14R7.0-28-90', d: '14', d1: '14', l: '28', L: '90', R: '7.0' },
                            { cipher: 'MN2 16R8.0-33-95', d: '16', d1: '16', l: '33', L: '95', R: '8.0' },
                            { cipher: 'MN2 18R9.0-33-95', d: '18', d1: '18', l: '33', L: '95', R: '9.0' },
                            { cipher: 'MN2 20R10.0-45-105', d: '20', d1: '20', l: '45', L: '105', R: '10.0' },
                        ]
                    },
                    {
                        id: 4,
                        title: 'Средняя серия',
                        head: ['Шифр', 'D', 'D1', 'l', 'L', 'C'],
                        values: [
                            { cipher: 'MN2 3R1.5/4-15-75', d: '3', d1: '4', l: '15', L: '75', C: '1.5' },
                            { cipher: 'MN2 4R2.0-20-75', d: '4', d1: '4', l: '20', L: '75', C: '2.0' },
                            { cipher: 'MN2 5R2.5/6-25-75', d: '5', d1: '6', l: '25', L: '75', C: '2.5' },
                            { cipher: 'MN2 6R3.0-45-100', d: '6', d1: '6', l: '45', L: '100', C: '3.0' },
                            { cipher: 'MN2 8R4.0-45-100', d: '8', d1: '8', l: '45', L: '100', C: '4.0' },
                            { cipher: 'MN2 10R5.0-45-100', d: '10', d1: '10', l: '45', L: '100', C: '5.0' },
                            { cipher: 'MN2 12R6.0-45-100', d: '12', d1: '12', l: '45', L: '100', C: '6.0' },
                            { cipher: 'MN2 14R7.0-45-100', d: '14', d1: '14', l: '45', L: '100', C: '7.0' },
                            { cipher: 'MN2 16R8.0-60-110', d: '16', d1: '16', l: '60', L: '125', C: '8.0' },
                            { cipher: 'MN2 18R9.0-60-125', d: '18', d1: '18', l: '60', L: '125', C: '9.0' },
                            { cipher: 'MN2 20R10.0-60-105', d: '20', d1: '20', l: '60', L: '125', C: '10.0' },
                        ]
                    },
                ],
                image: 'MN2.png',
                imageX: 'MN2.jpg'
            },
        ],
        images: [
            {id: 2, name: '', text: '', image: 'product_6.jpg'},
            {id: 3, name: '', text: '', image: 'product_5.jpg'},
            {id: 4, name: '', text: '', image: 'product_4.jpg'},
            {id: 5, name: '', text: '', image: 'product_3.jpg'},
            {id: 6, name: '', text: '', image: 'product_2.jpg'},
            {id: 7, name: '', text: '', image: 'product_1.jpg'},
        ]
    },
    actions: {

    },
    mutations: {

    }
}
