<template>
  <div class="why-us">
    <v-container class="why-us__container">
      <v-row>
        <v-col cols="12" md="6">
          <div class="why-us__image__wrap">
            <v-img class="why-us__image" :src="require('@/assets/images/home/why-us-bg.jpg')" cover></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="position-relative">
<!--          <div class="why-us__line__wrap">-->
<!--            <v-img class="why-us__line d-none d-xxl-flex" :src="require('@/assets/images/home/line.svg')"></v-img>-->
<!--            <v-img class="why-us__line d-none d-xl-flex d-xxl-none" :src="require('@/assets/images/home/line-xl.svg')" height="100%"></v-img>-->
<!--            <v-img class="why-us__line d-none d-lg-flex d-xl-none" :src="require('@/assets/images/home/line-lg.svg')" height="100%"></v-img>-->
<!--          </div>-->
          <v-row style="z-index: 1; position: relative">
<!--            <v-col cols="4">-->
<!--              <div class="why-us__stats">-->
<!--                <div class="why-us__stats__number"><span>100</span>%</div>-->
<!--                <div class="why-us__stats__text">-->
<!--                  Lorem ipsum dolor sit amet consectetur adipiscing elit-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
<!--            <v-col cols="4">-->
<!--              <div class="why-us__stats">-->
<!--                <div class="why-us__stats__number">+<span>100</span></div>-->
<!--                <div class="why-us__stats__text">-->
<!--                  Lorem ipsum dolor sit amet consectetur adipiscing elit-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
<!--            <v-col cols="4">-->
<!--              <div class="why-us__stats">-->
<!--                <div class="why-us__stats__number">+<span>10</span></div>-->
<!--                <div class="why-us__stats__text">-->
<!--                  Lorem ipsum dolor sit amet consectetur adipiscing elit-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-col>-->
            <v-col cols="12">
              <h2 class="why-us__title">Почему <span class="text--red">выбирают нас</span></h2>
              <p class="why-us__subtitle">«ВОЛЬФ ТЕХНОЛОДЖИ» - компания-производитель высокопроизводительного металлообрабатывающего инструмента и эксперт в области восстановления его режущих свойств.</p>
              <p class="why-us__text">
                Металлообрабатывающий инструмент, который мы производим:
              </p>
              <ul class="why-us__text">
                <li>надежен, стабилен в эксплуатации;</li>
                <li>повышает точность обработки;</li>
                <li>снижает количество брака;</li>
                <li>повышает производительность станка, а значит – и эффективность всего предприятия.</li>
              </ul>
              <p class="why-us__text">
                Наша продукция проходит тщательный выходной контроль качества на современной измерительной машине.
              </p>
              <p class="why-us__text">Наш сервис приятно удивит вас: мы не только осуществляем доставку заказов по всей стране, но и занимаемся сопровождением своего инструмента. Выезд к заказчику для определения и дальнейшего улучшения показателей и режущих свойств оснастки – обычная для нас практика.</p>
              <p class="why-us__text">Мы небольшое, а поэтому гибкое в своих подходах предприятие. Обычно нам нужна всего неделя для внесения изменений в геометрию инструмента, и мы уже готовы к его выпуску.  Также мы располагаем собственным складом готовой продукции, что еще больше расширяет ваши возможности.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
    name: 'PartWhyUs',

    components: {},

    props: ['loading'],

    data: () => ({}),
}

</script>

<style lang="sass">

.why-us
  margin-top: 72px

  &__image__wrap
    position: relative
    z-index: 10

  &__image

  &__line__wrap
    position: absolute
    top: 12px
    right: 12px
    bottom: 12px
    left: 12px
    z-index: 0

  &__line

  &__stats
    width: 100%
    text-align: center

    &__number
      font-size: 48px
      font-weight: bold
      color: #DB0133

    &__text
      padding: 0 40px
      font-size: 18px
      
  &__title
    position: relative
    //margin-top: 50px
    margin-right: 40px
    margin-left: 20px
    font-size: 42px
    text-transform: uppercase
    z-index: 5
    
  &__subtitle
    margin-right: 40px
    margin-left: 20px
    font-size: 24px
  
  &__text
    margin-top: 10px
    margin-right: 40px
    margin-left: 20px
    font-size: 20px
    line-height: 180%
    
  ul
    margin-left: 40px
    

@media (max-width: 2559.98px)
  .why-us
    margin-top: 60px

    &__image__wrap
      height: 100%
      
    &__image
      height: 100%
  
    &__line__wrap
      position: absolute
      top: 12px
      right: 12px
      bottom: 12px
      left: -4px
      z-index: 0
  
    &__stats
      &__number
        font-size: 42px
  
      &__text
        padding: 0 10px
        font-size: 16px
  
    &__title
      margin-right: 30px
      //margin-top: 10px
      margin-left: 10px
      font-size: 38px
  
    &__subtitle
      margin-right: 30px
      margin-left: 10px
      font-size: 20px
  
    &__text
      margin: 8px 30px 20px 10px
      font-size: 18px
      line-height: 157%
  
    ul
      margin-left: 28px
  
@media (max-width: 1919.98px)
  .why-us
    margin-top: 40px

    &__stats
      &__number
        font-size: 32px

      &__text
        padding: 0 10px
        font-size: 12px

    &__title
      margin-right: 20px
      margin-top: 0
      margin-left: 10px
      font-size: 30px

    &__subtitle
      margin-right: 20px
      margin-left: 10px
      font-size: 16px

    &__text
      margin: 8px 20px 20px 10px
      font-size: 14px
      line-height: 157%
    
    ul
      margin-left: 24px

@media (max-width: 1279.98px)
  .why-us
    margin-top: 30px
    
    &__stats
      &__number
        font-size: 30px
      
      &__text
        padding: 0
        font-size: 12px
    
    &__title
      margin: 0
      font-size: 28px
    
    &__subtitle
      margin: 0
      font-size: 14px
    
    &__text
      margin: 8px 0 0 0
      font-size: 13px
      line-height: 157%
    
    ul
      margin-left: 12px
      
@media (max-width: 959.98px)
  .why-us
    margin-top: 26px
    
    &__stats
      &__number
        font-size: 30px
      
      &__text
        font-size: 12px
    
    &__title
      font-size: 26px
    
    &__subtitle
      font-size: 14px
    
    &__text
      font-size: 13px
      line-height: 157%
      
@media (max-width: 599.98px)
  .why-us
    margin-top: 16px
    
    &__stats
      &__number
        font-size: 24px
      
      &__text
        font-size: 12px
    
    &__title
      font-size: 22px
    
    &__subtitle
      font-size: 12px
    
    &__text
      font-size: 14px
      line-height: 157%

</style>