<template>
  <div class="footer" id="footer">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column flex-md-row align-center justify-space-between">
            <div class="footer__logo">
              <v-img @click="$router.push({name: 'home'}).catch(() => {})"
                     :src="require('@/assets/images/logo.svg')" class="footer__logo__image" contain
              ></v-img>
            </div>
            <div class="footer__links">
              <span v-for="link of links" :key="'footer-link-' + link.to"
                    @click="$router.push({name: link.to, params: link.params}).catch(() => {})"
                    class="footer__link"
                    :class="{'footer__link--active': activeLink === link.to}"
              >{{ link.name }}</span>
            </div>
            <div class="footer__contacts d-none d-lg-flex">
              <span class="footer__contacts__phone" @click="openTel()"><img :src="require('@/assets/images/icons/phone--white--outline.svg')" alt="phone">+7-913-919-84-34</span>
              <span class="footer__contacts__email" @click="openEmail('all@wolf-tools.ru')"><v-icon style="margin-bottom: 3px">mdi-email-outline</v-icon> all@wolf-tools.ru</span>
              <span class="footer__contacts__instagram" @click="openInst()"><img :src="require('@/assets/images/icons/instagram.svg')" alt="instagram">_wolf_technology_</span>
            </div>
            <div class="footer__contacts d-flex d-lg-none align-center">
              <span class="footer__contacts__phone" @click="openTel()"><img :src="require('@/assets/images/icons/phone--white--outline.svg')" alt="phone"></span>
              <span class="footer__contacts__email" @click="openEmail('all@wolf-tools.ru')"><v-icon>mdi-email-outline</v-icon></span>
              <span class="footer__contacts__instagram" @click="openInst()"><img :src="require('@/assets/images/icons/instagram.svg')" alt="instagram"></span>
            </div>
            <span class="footer__address" @click="openMap()"><v-icon style="margin-bottom: 3px">mdi-map-marker-outline</v-icon> г.Новосибирск, ул.Восточная 4/1</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
    name: 'EmptyTemplate',

    components: {},

    props: ['loading'],

    data: () => ({
        links: [
            {name: 'Главная', to: 'home'},
            {name: 'О нас', to: 'about'},
            {name: 'Продукция', to: 'products'},
            {name: 'Портфолио', to: 'works', params: {page: '1'}},
            {name: 'Калькулятор', to: 'calc'},
            {name: 'Контакты', to: 'contacts'},
        ]
    }),

    computed: {
        activeLink() {
            return this.$route.name
        }
    },

    methods: {
        openTel() {
            let number = '+7 (913) 919 84-34'
            let phone = number.split(' ').join('')
            // eslint-disable-next-line no-useless-escape
            phone = phone.replace(/\(|\)|\-/g, '')
            if (number.startsWith('8' || '7')) {
                phone = phone.split('')
                phone.splice(0, 1, '+7')
                phone = phone.join('')
            }
            window.location.href = 'tel:' + phone
        },

        openEmail(email) {
            window.location.href = `mailto:${email}`
        },

        openInst() {
            window.open(`https://instagram.com/_wolf_technology_?utm_medium=copy_link`, '_blank');
        },

        openMap() {
            window.open(`https://yandex.ru/maps/org/volf_tekhnolodzhi/125419001112/?from=mapframe&ll=82.997721%2C54.921029&z=17`, '_blank');
        }
    }
}

</script>


<style lang="sass">

.footer
  margin-top: 56px
  width: 100%
  padding: 15px 0
  background: #000000

  &__logo
    height: auto
    width: 300px
    cursor: pointer

  &__links
    display: flex
    align-items: center
    font-size: 16px
    color: #ffffff

  &__link
    margin-right: 20px
    cursor: pointer

    &--active
      border-bottom: red 1px solid

  &__contacts
    display: flex
    align-items: center
    font-size: 16px
    color: #ffffff

    span
      margin-right: 20px
      cursor: pointer
      
    &__phone
      display: flex
      img
        width: 23px
        height: auto
        margin-right: 4px
    &__instagram
      display: flex
      img
        width: 27px
        height: auto
        margin-right: 4px

  &__address
    margin-top: auto
    margin-bottom: auto
    font-size: 16px
    color: #ffffff
    cursor: pointer

@media (max-width: 2559.98px)

@media (max-width: 1919.98px)

  .footer
    margin-top: 30px

    &__logo
      width: 140px

    &__links
      font-size: 14px

    &__link
      margin-right: 20px
      cursor: pointer

      &--active
        border-bottom: red 1px solid

    &__contacts
      display: flex
      align-items: center
      font-size: 14px
      color: #ffffff

      span
        margin-right: 20px
        cursor: pointer

      &__phone
        img
          width: 20px
          height: auto
      &__instagram
        margin-right: 0 !important
        img
          width: 20px
          height: auto

    &__address
      display: none

@media (max-width: 1279.98px)
  
  .footer
    margin-top: 26px
    
    &__logo
      width: 140px
    
    &__links
      font-size: 14px
    
    &__link
      margin-right: 16px
      cursor: pointer
      
      &--active
        border-bottom: red 1px solid
    
    &__contacts
      display: flex
      align-items: center
      font-size: 14px
      color: #ffffff
      
      span
        margin-right: 20px
        cursor: pointer
      
      &__phone
        img
          width: 20px
          height: auto
          margin-right: 0
      &__instagram
        margin-right: 0 !important
        img
          width: 20px
          height: auto
          margin-right: 0
    
    &__address
      display: none

@media (max-width: 959.98px)
  
  .footer
    margin-top: 26px
    
    &__logo
      width: 140px
    
    &__links
      margin: 16px 0
    
    &__contacts
      display: flex
      align-items: center
      font-size: 20px
      color: #ffffff
      
      &__phone
        img
          width: 30px
      &__instagram
        img
          width: 30px

@media (max-width: 599.98px)
  
  .footer
    margin-top: 26px
    
    &__logo
      width: 140px
    
    &__links
      flex-wrap: wrap
      align-items: center
      justify-content: center
      gap: 10px
      margin: 10px 0 16px 0
    &__link
      margin-top: 10px
      margin-right: 0
      &:nth-child(1)
        margin-left: 0

</style>