export const animations = {
    namespaced: true,
    state: {
        states: {
            mainForm: false
        },
        timelines: {
            navbarAnimation: null,
            mainAnimation: null
        },
        scrollMagic: {
            controllerHome: null
        }
    },
    mutations: {
        setControllerHome(state, controller) {
            state.scrollMagic.controllerHome = controller
        }

    },
    actions: {
        setControllerHome({commit}, controller) {
            commit('setControllerHome', controller)
        }
    }
}
