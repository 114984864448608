<template>
  <transition name="expand">
    <div class="load" v-if="loading">
      <div class="load-logo">
        <span class="loader"></span>
      </div>
      <div class="load-test"></div>
    </div>
  </transition>
</template>

<script>

import { TimelineMax } from "gsap"

export default {
    name: 'AppLoader',
    props: ['loading'],
    
    methods: {
        startLoaderAnimation() {
            const loaderAnimation = new TimelineMax()
            
            loaderAnimation.to('.loader', 0.5, {
                opacity: 0
            })
        }
    },
    
    watch: {
        loading(newValue) {
            if (!newValue) this.startLoaderAnimation()
        }
    }
}
</script>

<style lang="sass">

.load
  height: 100vh
  width: 100vw
  display: flex
  flex-direction: column
  justify-content: center
  background: #000000
  z-index: 1000
    

.loader 
  width: 10px
  height: 10px
  border-radius: 50%
  display: block
  margin: 15px auto
  position: relative
  color: #FFF
  left: -100px
  box-sizing: border-box
  animation: shadowRolling 2s linear infinite


@keyframes shadowRolling
  0% 
    box-shadow: 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0)
  12% 
    box-shadow: 100px 0 white, 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0)
  25% 
    box-shadow: 110px 0 white, 100px 0 white, 0 0 rgba(255, 255, 255, 0), 0 0 rgba(255, 255, 255, 0)
  36% 
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0 0 rgba(255, 255, 255, 0)
  50% 
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white
  62% 
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white
  75% 
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white
  87% 
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white
  100% 
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0)


    
.expand-enter-active
  transition: all 1s


.expand-leave-active
  transition: all 1.5s


.expand-enter-from,
.expand-leave-to
  animation: expand 1.5s


@keyframes expand
  from
    height: 100vh

  to
    height: 0

</style>
