<template>
  <div>
    <v-container fluid class="calc__header__container">
      <v-container>
        <v-row>
          <v-col cols="12" md="10" lg="8" xl="6">
            <h1 class="calc__header__title">Калькулятор</h1>
            <v-form class="calc__header__form" ref="calcForm">
              <v-row>
                <v-col cols="12" sm="6" lg="5" class="py-0">
                  <v-text-field class="calc__header__input" label="Введите скорость резания (м/мин)" :rules="rules.canNotBeNull" v-model="speed"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="5" class="py-0">
                  <v-text-field class="calc__header__input" label="Введите диаметр (мм)" :rules="rules.canNotBeNull" v-model="diameter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="5" class="py-0">
                  <v-text-field class="calc__header__input" label="Введите подачу (мм/зуб.)" :rules="rules.canNotBeNull" v-model="tooth"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="5" class="py-0">
                  <v-text-field class="calc__header__input" label="Введите количество зубьев" :rules="rules.canNotBeNull" v-model="numberOfTeeth"></v-text-field>
                </v-col>
                <v-col cols="12" lg="10" class="d-flex justify-space-between flex-column flex-sm-row">
                  <button class="calc__header__btn btn order-2 order-sm-1" @click.prevent="calculate()">Получить расчет</button>
                  
                  <div class="calc__header__results order-1 order-sm-2" v-if="turnover !== null && innings !== null">
                    <p>Количество оборотов = {{ turnover }} (об/мин)</p>
                    <p>Подача = {{ innings }} (мм/мин)</p>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <!--          <v-col cols="4" xl="6">-->
          <!--            <p class="calc__header__text">-->
          <!--              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit scelerisque commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit scelerisque commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit-->
          <!--              scelerisque commodo.-->
          <!--            </p>-->
          <!--          </v-col>-->
        </v-row>
      </v-container>
    </v-container>
    
    <!--    <v-container class="calc__container">-->
    <!--      <v-row>-->
    <!--        <v-col cols="12">-->
    <!--          <v-img :src="require('@/assets/images/calc/table.jpg')"></v-img>-->
    <!--        </v-col>-->
    <!--        <v-col cols="12">-->
    <!--          <v-img :src="require('@/assets/images/calc/table.jpg')"></v-img>-->
    <!--        </v-col>-->
    <!--        <v-col cols="12">-->
    <!--          <v-img :src="require('@/assets/images/calc/table.jpg')"></v-img>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-container>-->
  </div>
</template>

<script>

// Components

export default {
  name: 'ContactsView',
  
  data: () => ({
    speed: '',
    diameter: '',
    tooth: '',
    numberOfTeeth: '',
    turnover: null,
    innings: null,
    rules: {
      canNotBeNull: [
        v => !!v || 'Заполните поле',
        v => /^-?(0|[1-9]\d*)(\.[0-9]{1,20})?$/.test(v) || 'Введите число'
      ]
    }
  }),
  
  components: {},
  
  methods: {
    async calculate() {
      let form = await this.$refs.calcForm.validate()
      if (form.valid) {
        let S = (this.speed * 1000) / (3.14 * this.diameter),
          Vf = this.tooth * this.numberOfTeeth * S
        this.turnover = Math.round(S)
        this.innings = Math.round(Vf)
      }
    }
  }
}
</script>

<style lang="sass">

.calc
  &__header
    &__container
      padding: 30px 0 20px 0 !important
      background-image: url("@/assets/images/calc/header__bg.png")
      background-size: cover
    
    &__title
      line-height: 100%
      font-size: 46px
      text-transform: uppercase
      color: #ffffff
    
    &__form
      margin-top: 30px
    
    &__input
      color: rgba(255, 255, 255, 0.6)
      
      .v-input__control
        background-color: rgba(255, 255, 255, 0.14)
    
    &__text
      margin-top: 20px
      font-size: 20px
      color: #ffffff
    
    &__results
      color: #ffffff
      font-size: 15px
  
  &__container
    margin-top: 38px

@media (max-width: 2559.98px)
  .calc
    &__header
      &__container
        padding: 30px 0 20px 0 !important
      
      &__title
        font-size: 40px
      
      &__form
        margin-top: 30px
      
      &__text
        margin-top: 20px
        font-size: 18px
        color: #ffffff
    
    &__container
      margin-top: 38px

@media (max-width: 1919.98px)
  .calc
    &__header
      &__container
        padding: 30px 0 20px 0 !important
      
      &__title
        font-size: 32px
      
      &__form
        margin-top: 30px
      
      &__text
        font-size: 16px
    
    &__container
      margin-top: 16px

@media (max-width: 1279.98px)
  
  .calc
    &__header
      &__title
        font-size: 30px

@media (max-width: 959.98px)
  
  .calc
    &__header
      &__title
        font-size: 28px
      
      &__input
        .v-label
        font-size: 14px !important
        
        .v-field-label--floating
          font-size: 11px !important
        
        .v-field__input
          font-size: 14px

@media (max-width: 599.98px)
  
  .calc
    &__header
      &__title
        font-size: 26px
      
      &__input
        .v-label
        
        font-size: 12px !important
        
        .v-field-label--floating
          font-size: 9px !important
        
        .v-field__input
          font-size: 12px
    
      &__results
        color: #ffffff
        font-size: 14px
        margin-bottom: 12px

</style>
