<template>
  <div class="home-offer">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="home-offer__title">Наши <span class="text--red">предложения</span></h2>
        </v-col>
        
        <v-col cols="12" sm="6">
          <v-card class="home-offer__service__card">
            <v-row class="row-full-height">
              <!--              <v-col cols="2"></v-col>-->
              <v-col cols="12">
                <h3 class="home-offer__service__title">Производство металлообрабатывающего инструмента</h3>
                <p class="home-offer__service__description">Мы производим универсальные концевые и радиусные фрезы для обработки всех групп материалов по ISO.<br>Изготавливаем инструмент по чертежам заказчика!</p>
              </v-col>
              <v-col cols="12" class="align-self-end">
                <button class="home-offer__service__btn btn" @click="$router.push({name: 'products'})">Подробнее</button>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="home-offer__service__card">
            <v-row>
              <!--              <v-col cols="2"></v-col>-->
              <v-col cols="12">
                <h3 class="home-offer__service__title">Восстановление режущих свойств инструмента</h3>
                <p class="home-offer__service__description">В короткие сроки восстановим эксплуатационные свойства металлообрабатывающего инструмента. Используем заточку по передней поверхности, шлифование профиля инструмента по наружному диаметру и торцу, нанесение нового износоустойчивого покрытия.</p>
              </v-col>
              <v-col cols="12" class="align-self-end">
                <button class="home-offer__service__btn btn" @click="$emit('scrollToContacts')">Связаться с нами</button>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        
        <v-col cols="12">
          <div class="d-flex justify-space-between mt-6">
            <h2 class="home-offer__subtitle">Продукция</h2>
            
            <span class="home-offer__link-to-products my-auto" @click="$router.push({name: 'products', hash: '#products'})">Вся продукция <v-icon size="26">mdi-chevron-double-right</v-icon></span>
          </div>
        </v-col>
        <v-col cols="12" sm="6" v-for="product of productsWithTables" :key="'productWithTables-' + product.id">
          <ProductCard :product="product" :table="true"></ProductCard>
        </v-col>
        
        <v-col cols="12">
          <h2 class="home-offer__subtitle mt-3">ОБРАЗЦЫ СПЕЦИАЛЬНОГО ИНСТРУМЕНТА ПО ЧЕРТЕЖАМ ЗАКАЗЧИКА</h2>
        </v-col>
        
        <v-col cols="6" sm="4" md="3" lg="3" xl="2" v-for="product of productsImages" :key="'productImages-' + product.id">
          <ProductCard :product="product"></ProductCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ProductCard from '@/components/ProductCard.vue'
import { mapState } from "vuex";

export default {
  name: 'PartProducts',
  
  components: {ProductCard},
  
  computed: {
    ...mapState({
      productsWithTables: state => state.products.withTables,
      productsImages: state => state.products.images,
    })
  }
}

</script>

<style lang="sass">

.home-offer
  margin-top: 48px
  
  &__title
    line-height: 100%
    font-size: 42px
    text-transform: uppercase
  
  &__subtitle
    font-size: 24px
    text-transform: uppercase
    color: #DB0133
  
  &__link-to-products
    color: #DB0133
    font-size: 18px
    cursor: pointer
  
  
  &__service
    
    &__card
      height: 100%
      padding-top: 24px
      border: 1px solid rgba(59, 59, 59, 0.40)
      border-radius: 4px
      background: #F4F4F4
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15)
    
    &__title
      margin-bottom: 10px
      margin-right: 24px
      margin-left: 24px
    
    &__description
      margin-right: 24px
      margin-left: 24px
    
    &__btn
      width: 100%

@media (max-width: 2559.98px)
  .home-offer
    margin-top: 36px
    
    &__title
      font-size: 38px
    
    &__subtitle
      font-size: 24px

@media (max-width: 1919.98px)
  .home-offer
    margin-top: 16px
    
    &__title
      font-size: 30px
    
    &__subtitle
      font-size: 20px

@media (max-width: 1279.98px)
  .home-offer
    margin-top: 0
    
    &__title
      font-size: 28px
    
    &__subtitle
      font-size: 20px
    
    &__link-to-products
      color: #DB0133
      font-size: 18px
      cursor: pointer
    
    
    &__service
      
      &__title
        margin-bottom: 10px
        margin-right: 24px
        margin-left: 24px
      
      &__description
        margin-right: 24px
        margin-left: 24px
        font-size: 16px

@media (max-width: 959.98px)
  .home-offer
    
    &__title
      font-size: 26px
    
    &__subtitle
      font-size: 18px
    
    &__link-to-products
      color: #DB0133
      font-size: 14px
      cursor: pointer
    
    &__service
      
      &__card
        padding-top: 18px
      
      &__title
        margin-bottom: 10px
        margin-right: 18px
        margin-left: 18px
        font-size: 16px
      
      &__description
        margin-right: 18px
        margin-left: 18px
        font-size: 14px

@media (max-width: 599.98px)
  .home-offer
    
    &__title
      font-size: 22px
    
    &__subtitle
      font-size: 16px
    
    &__link-to-products
      color: #DB0133
      font-size: 14px
      cursor: pointer
    
    &__service
      
      &__card
        padding-top: 18px
      
      &__title
        margin-bottom: 10px
        margin-right: 18px
        margin-left: 18px
        font-size: 16px
      
      &__description
        margin-right: 18px
        margin-left: 18px
        font-size: 14px

</style>