import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueParticles from 'vue-particles'
import VueAwesomeSwiper from "vue-awesome-swiper"
// import { VueReCaptcha } from 'vue-recaptcha-v3'

import {loadFonts} from './plugins/webfontloader'
import './assets/style/fonts.sass'

loadFonts()

createApp(App)
    .use(router)
    .use(store)
    // .use(VueReCaptcha, { siteKey: '6LfrJoIpAAAAAMZJy3dKcSaXfo3TaB5-REtpejkh' })
    .use(vuetify)
    .use(VueParticles)
    .use(VueAwesomeSwiper)
    .mount('#app')
