import { createStore } from 'vuex'

import { animations } from "@/store/modules/animations.module"
import { notify } from "@/store/modules/notify.module"
import { order } from "@/store/modules/order.module"
import { products } from "@/store/modules/products.module"
export default createStore({
  modules: {
    animations, notify, order, products
  }
});
