<template>
  <PartMain/>
  <PartWhyUs/>
  <PartProducts @scrollToContacts="$emit('scrollToContacts')"/>
<!--  <PartPortfolio/>-->
<!--  <PartReviews/>-->
<!--  <PartNews/>-->
</template>

<script>

import ScrollMagic from 'scrollmagic'
import 'debug.addIndicators'
import { TweenMax, TimelineMax } from "gsap"
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap"
import { CountUp } from "countup.js"

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

// Components
import PartMain from '@/components/home/PartMain.vue';
import PartWhyUs from "@/components/home/PartWhyUs.vue";
import PartProducts from "@/components/home/PartProducts.vue";
// import PartPortfolio from "@/components/home/PartPortfolio.vue";
// import PartReviews from "@/components/home/PartReviews.vue";
// import PartNews from "@/components/home/PartNews.vue";
import {mapState} from "vuex";

export default {
    name: 'HomeView',

    components: {
        // PartReviews,
        // PartPortfolio,
        PartProducts,
        PartWhyUs,
        PartMain,
        // PartNews,
    },
    
    data: () => ({
        controllerInit: false
    }),
    
    computed: {
        ...mapState({
            controller: state => state.animations.scrollMagic.controllerHome
        })
    },
    
    mounted() {

    },
    
    watch: {
        controller(newValue) {
            if (newValue !== null && !this.controllerInit) {
                this.controllerInit = true
                this.setAnimations()
            }
        }
    },
    
    methods: {
        setAnimations() {
            document.querySelectorAll('.why-us__stats__number span').forEach((el) => {
                let countUp = new CountUp(el, 100, { enableScrollSpy: true, scrollSpyOnce: true })

                countUp.start()
            })

            let titles = ['.why-us__title', '.home-offer__title', '.home-portfolio__title', '.reviews__title', '.home-news__title', '.home-contacts__title']

            if (this.controller !== null) {
                for (let i = 0; i < titles.length; i++) {
                    this.setTitleAnimation(titles[i], i)
                }

                document.querySelectorAll('.product__card').forEach((el) => {
                    this.setProductAnimation(el)
                })

                document.querySelectorAll('.home-news__card').forEach((el, index) => {
                    this.setNewsAnimation(el, index)
                })
            }
        },
        
        titlesAnimate(target, n) {
            return new TweenMax.from(target, 1.2, {
                opacity: 0,
                x: n === 0 || n === 5 ? 100 : -100
            });
        },
        
        setTitleAnimation(target, n) {
            new ScrollMagic
                .Scene({triggerElement: target, triggerHook: 0.9, reverse: false})
                .setTween(this.titlesAnimate(target, n))
                .addTo(this.controller)
        },

        productsAnimate(target) {
            const tm = new TimelineMax()

            let title = target.querySelector('.product__title'),
                description = target.querySelector('.product__description'),
                btns = target.querySelector('.product__btns'),
                img = target.querySelector('.product__img')
            
            tm.from(title, 0.6, {
                opacity: 0,
                y: 400
            })
            
            tm.from(description, 1.2, {
                opacity: 0,
                y: 400
            }, '<')
            
            tm.from(btns, 1.8, {
                opacity: 0,
                y: 400
            }, '<')
            
            tm.from(img, 1.8, {
                opacity: 0,
                x: 400
            }, '<')
            
            return tm
        },
        
        setProductAnimation(target) {
            new ScrollMagic
                .Scene({triggerElement: target, triggerHook: 0.8, reverse: false})
                .setTween(this.productsAnimate(target))
                .addTo(this.controller)
        },

        newsAnimate(target, index) {
            const tm = new TimelineMax()
            
            tm.from(target, 1.2, {
                x: index % 2 === 0 ? '120%' : '-120%'
            })
            
            return tm
        },

        setNewsAnimation(target, index) {
            new ScrollMagic
                .Scene({triggerElement: target, triggerHook: 0.8, reverse: false})
                .setTween(this.newsAnimate(target, index))
                .addTo(this.controller)
        }
    }
}
</script>
