<template>
  <div class="main">
    <v-container class="main__container">
      <v-row>
        <v-col cols="12" lg="5">
          <div class="main__title-wrap">
            <h1 class="main__title">«ВОЛЬФ ТЕХНОЛОДЖИ»</h1>
            <p class="main__subtitle">Немецкое качество из Сибири</p>
          </div>
        </v-col>
        <v-col cols="12" lg="6" offset-lg="1">
          <v-row>
            <v-col cols="12" md="10">
              <div class="main__card">
                <v-img :src="require('@/assets/images/home/main__card__image-first.jpg')" alt="quality" class="main__card__image" cover></v-img>
                <span class="main__card__title">Собственное производство фрез</span>
              </div>
            </v-col>
            <v-col cols="12" md="10" offset-md="2">
              <div class="main__card">
                <v-img :src="require('@/assets/images/home/main__card__image-second.jpg')" alt="family" class="main__card__image" cover></v-img>
                <span class="main__card__title">Все для удобства заказчика</span>
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <div class="main__card">
                <v-img :src="require('@/assets/images/home/main__card__image-third.jpg')" alt="innovation" class="main__card__image" cover></v-img>
                <span class="main__card__title">Восстановление режущих свойств инструмента</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="main__container--fluid pa-0">
      <v-row>
        <v-col cols="12" class="pa-0">
          <div class="main__facts" id="animate01">
            <div class="main__facts__elem">
              <div class="main__facts__elem__image">
                <v-img :src="require('@/assets/images/icons/quality.svg')"></v-img>
              </div>
              <div class="main__facts__elem__title">Качество</div>
            </div>
            <div class="main__facts__elem">
              <div class="main__facts__elem__image">
                <v-img :src="require('@/assets/images/icons/management.svg')"></v-img>
              </div>
              <div class="main__facts__elem__title">Инновации</div>
            </div>
            <div class="main__facts__elem">
              <div class="main__facts__elem__image">
                <v-img :src="require('@/assets/images/icons/service.svg')"></v-img>
              </div>
              <div class="main__facts__elem__title">Инжиниринг</div>
            </div>
            <div class="main__facts__elem">
              <div class="main__facts__elem__image">
                <v-img :src="require('@/assets/images/icons/support.svg')"></v-img>
              </div>
              <div class="main__facts__elem__title">Сервис</div>
            </div>
            <div class="main__facts__elem main__facts__elem__last">
              <div class="main__facts__elem__image">
                <v-img :src="require('@/assets/images/icons/convenience.svg')"></v-img>
              </div>
              <div class="main__facts__elem__title">Скорость поставок</div>
            </div>
            <div class="main__facts__btn btn" @click="$router.push({name: 'about'})">Подробнее о компании</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { TimelineMax } from "gsap"
import {mapState} from "vuex";
export default {
    name: 'PartMain',

    components: {},

    props: ['loading'],

    data: () => ({
    
    }),
    
    computed: {
        ...mapState({
            loaded: state => state.animations.timelines.mainAnimation
        })
    },
    
    mounted() {
        if (this.loaded === null) {
            if (!this.loading) {
                setTimeout(() => {
                    this.startMainAnimation()
                }, 2100)
            }
        }
    },
    
    methods: {
        startMainAnimation() {
            const mainAnimation = new TimelineMax()

            mainAnimation.from('.main__title, .main__subtitle', 1.4, {
                opacity: 0,
                x: -220,
            })

            mainAnimation.from('.main__card__image', 1.4, {
                y: 180,
                stagger: 0.8
            }, '<')

            mainAnimation.from('.main__card__title', 1.4, {
                y: 180,
                stagger: 0.8
            }, '<')

            mainAnimation.from('.main__facts__elem__image', 1.4, {
                opacity: 0,
                stagger: 0.5
            }, '<')

            mainAnimation.from('.main__facts__elem__title', 1.4, {
                y: 180,
                stagger: 0.3
            }, '<')

            this.$store.state.animations.timelines.mainAnimation = mainAnimation
        },
    }
}
</script>

<style lang="sass">

.main
  background-image: url("@/assets/images/home/main-bg.png")
  background-position: center
  background-size: cover
  min-height: calc(100vh - 64px)
  display: flex
  flex-direction: column

  &__container
    margin-top: auto
    margin-bottom: auto

  &__title-wrap
    display: flex
    flex-direction: column
    justify-content: center
    height: 100%

  &__title
    text-transform: uppercase
    font-size: 87px
    line-height: 115px
    letter-spacing: -3px

  &__subtitle
    font-size: 36px
    text-align: end

  &__card
    display: flex
    justify-content: flex-end
    align-items: center
    position: relative
    height: 180px
    margin-bottom: -4px
    border-bottom: 4px solid #DB0133
    border-radius: 0 0 4px 4px
    background: none
    overflow: hidden

    &__title
      margin-right: 30px
      text-align: end
      text-transform: uppercase
      font-size: 22px
      font-weight: bold
      color: #ffffff
      z-index: 1

    &__image
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0

  &__facts
    display: flex
    justify-content: center
    padding-top: 40px
    padding-bottom: 40px
    background: rgba(117, 118, 119, 0.50)
    backdrop-filter: blur(10px)
    overflow: hidden

    &__elem
      display: flex
      flex-direction: column
      align-items: center
      margin-right: 145px

      &__image
        display: flex
        align-items: center
        width: 80px
        height: 80px
        border-radius: 100%
        background-color: #ffffff
        
        .v-img
          width: auto
          height: 46px

      &__title
        margin-top: 10px
        text-transform: uppercase

    &__btn
      align-self: center

@media (max-width: 2559.98px)
  .main
    &__container
  
    &__title
      font-size: 66px
      line-height: 87px
      
    &__subtitle
      font-size: 32px
      
    &__card
      height: 160px
      
      &__title
        font-size: 20px

@media (max-width: 1919.98px)
  .main
    &__container

    &__title
      font-size: 44px
      line-height: 50px

    &__subtitle
      font-size: 20px

    &__card
      height: 120px

      &__title
        font-size: 16px

    &__facts
      padding-top: 20px
      padding-bottom: 20px
  
      &__elem
        margin-right: 60px
  
        &__image
          width: 60px
          height: 60px
          
          .v-img
            width: auto
            height: 30px
  
        &__title
          margin-top: 6px
          font-size: 14px

@media (max-width: 1279.98px)

  .main
    background-image: url("@/assets/images/home/main-bg.png")
    
    &__title-wrap
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
    
    &__title
      text-transform: uppercase
      font-size: 40px
      line-height: 100%
      letter-spacing: 1px
    
    &__subtitle
      font-size: 24px
      text-align: start
    
    &__card
      height: 160px
      
      &__title
        font-size: 20px
    
    &__facts
      padding-top: 30px
      padding-bottom: 30px
      
      &__elem
        margin-right: 47px
        
        &__image
          width: 50px
          height: 50px
          
          .v-img
            height: 30px
        
        &__title
          margin-top: 10px
          text-transform: uppercase
          font-size: 14px
      
      &__btn
        align-self: center
        
@media (max-width: 959.98px)
  
  .main
    background-image: url("@/assets/images/home/main-bg.png")
    
    &__title-wrap
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
    
    &__title
      text-transform: uppercase
      font-size: 32px
      line-height: 100%
    
    &__subtitle
      font-size: 18px
      text-align: start
  
    &__card
      height: 120px
      
      &__title
        margin-right: 20px
        font-size: 14px
    
    &__facts
      flex-wrap: wrap
      padding-top: 20px
      padding-bottom: 20px
      
      &__elem
        
        &__last
          margin-right: 0
        
        &__image
          width: 50px
          height: 50px
          
          .v-img
            height: 30px
        
        &__title
          margin-top: 10px
          text-transform: uppercase
          font-size: 12px
          text-align: center
      
      &__btn
        align-self: center
        margin-top: 20px
        margin-left: 110px
        margin-right: 110px
        
@media (max-width: 599.98px)
  
  .main
    background-image: url("@/assets/images/home/main-bg.png")
    
    &__title-wrap
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
    
    &__title
      text-transform: uppercase
      font-size: 32px
      line-height: 100%
    
    &__subtitle
      font-size: 18px
      text-align: start
    
    &__card
      height: 80px
      
      &__title
        margin-right: 20px
        font-size: 14px
    
    &__facts
      flex-wrap: wrap
      padding-top: 20px
      padding-bottom: 20px
      
      &__elem
        width: 30%
        margin-right: 0
        &:nth-child(4)
          margin-top: 20px
        &:nth-child(5)
          margin-top: 20px
        
        &__image
          width: 50px
          height: 50px
          
          .v-img
            height: 30px
        
        &__title
          margin-top: 10px
          text-transform: uppercase
          font-size: 12px
      
      &__btn
        align-self: center
        margin-left: 20px
        margin-right: 20px
</style>
