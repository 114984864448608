<template>
  <div>
    <v-container fluid class="portfolio__header__container">
      <v-container>
        <v-row>
          <v-col cols="8" xl="6">
            <h1 class="portfolio__header__title">Портфолио</h1>
<!--            <p class="portfolio__header__text">-->
<!--              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit scelerisque commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit scelerisque commodo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ullamcorper est eu elit-->
<!--              scelerisque commodo.-->
<!--            </p>-->
          </v-col>
<!--          <v-col cols="4" xl="6">-->
<!--            <v-row class="portfolio__categories">-->
<!--              <v-col cols="6" xl="4" v-for="category of categories" :key="'category-name-' + category.id">-->
<!--                <button class="portfolio__category" :class="{ 'portfolio__category&#45;&#45;active': activeCategory === category.id }" @click="activeCategory = category.id">{{ category.title }}</button>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-col>-->
        </v-row>
      </v-container>
    </v-container>

<!--    <v-container class="portfolio__container">-->
<!--      <v-row>-->
<!--        <v-col cols="2" v-for="(image, i) of categories[activeCategory].images" :key="'home-portfolio-image-' + i">-->
<!--          <div>-->
<!--            <v-img :src="require(`@/assets/images/home/${ image }`)"></v-img>-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-container>-->
  </div>
</template>

<script>

// Components

export default {
    name: 'PortfolioView',

    data: () => ({
        categories: [
            {id: 0, title: 'Категория 1', images: ['portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg']},
            {id: 1, title: 'Категория 2', images: ['portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg']},
            {id: 2, title: 'Категория 3', images: ['portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg']},
            {id: 3, title: 'Категория 4', images: ['portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg', 'portfolio-image.jpg']},
        ],
        activeCategory: 3
    }),

    components: {},
}
</script>

<style lang="sass">

.portfolio
  &__header
    &__container
      padding: 100px 0 100px 0 !important
      background-image: url("@/assets/images/portfolio/header__bg.png")
      background-size: cover

    &__title
      line-height: 100%
      font-size: 46px
      text-transform: uppercase
      color: #000000
      
    &__text
      margin-top: 20px
      font-size: 20px

  &__container
    margin-top: 38px
    
  &__category
    width: 100%
    padding: 20px 0
    border-radius: 2px
    background-color: #ffffff
    font-size: 18px
    
    &--active
      background-color: #DB0133
      color: #ffffff

@media (max-width: 2559.98px)

  .portfolio
    &__header
      &__container
        padding: 80px 0 80px 0 !important
  
      &__title
        font-size: 40px
  
      &__text
        margin-top: 20px
        font-size: 18px
  
    &__container
      margin-top: 38px
  
    &__category
      padding: 16px 0
      font-size: 16px

@media (max-width: 1919.98px)

  .portfolio
    &__header
      &__container
        padding: 38px 0 30px 0 !important

      &__title
        font-size: 32px

      &__text
        margin-top: 20px
        font-size: 16px

    &__container
      margin-top: 16px

    &__category
      padding: 10px 0
      font-size: 16px

@media (max-width: 1259.98px)

@media (max-width: 959.98px)

@media (max-width: 599.98px)

</style>
