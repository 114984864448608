<template>
  <div class="home-contacts">
    <v-container class="home-contacts__container">
      <v-row>
        <v-col cols="12" md="6">
          <div class="home-contacts__map__wrap">
            <a href="https://yandex.ru/maps/org/volf_tekhnolodzhi/125419001112/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Вольф Технолоджи</a>
            <a href="https://yandex.ru/maps/11316/novosibirsk-oblast/category/metalworking_equipment/184106660/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Металлообрабатывающее оборудование в Новосибирской области</a>
            <a href="https://yandex.ru/maps/11316/novosibirsk-oblast/category/metalwork/184106662/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:28px;">Металлообработка в Новосибирской области</a>
            <iframe src="https://yandex.ru/map-widget/v1/?ll=82.997780%2C54.920992&mode=search&oid=125419001112&ol=biz&utm_source=main_stripe_big&z=18.83" width="100%" height="100%" allowfullscreen="true" style="position:relative; border: none"></iframe>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="position-relative pl-xl-10 pl-xxl-16">
<!--          <div class="home-contacts__line__wrap">-->
<!--            <v-img class="home-contacts__line d-none d-xxl-flex" :src="require('@/assets/images/home/line-contacts.svg')"></v-img>-->
<!--            <v-img class="home-contacts__line d-none d-xl-flex d-xxl-none" :src="require('@/assets/images/home/line-contacts-xl.svg')"></v-img>-->
<!--          </div>-->
          <v-row>
            <v-col cols="12">
              <div class="home-contacts__header">
                <h2 class="home-contacts__title">Наши <span class="text--red">контакты</span></h2>
                <div class="home-contacts__items">
                  <div class="home-contacts__items__address" @click="openMap()">
                    <div>
                      <v-icon>mdi-map-marker-outline</v-icon>
                    </div>
                    <span>г.Новосибирск, ул.Восточная 4/1</span>
                  </div>
                  <div class="home-contacts__items__phone" @click="openTel()">
                    <div>
                      <img :src="require('@/assets/images/icons/phone--outline.svg')" alt="phone">
                    </div>
                    <span>+7-913-919-84-34</span>
                  </div>
                  <div class="home-contacts__items__email" @click="openEmail('all@wolf-tools.ru')">
                    <div>
                      <v-icon>mdi-email-outline</v-icon>
                    </div>
                    <span>all@wolf-tools.ru</span>
                  </div>
                  <div class="home-contacts__items__instagram" @click="openInst()">
                    <div>
                      <img :src="require('@/assets/images/icons/instagram.svg')" alt="instagram">
                    </div>
                    <span>_wolf_technology_</span>
                  </div>
                </div>
              </div>
            </v-col>
<!--            <v-col cols="12">-->
<!--              <AppForm></AppForm>-->
<!--            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

// import AppForm from "@/components/AppForm.vue";
export default {
    name: 'PartWhyUs',

    components: {
      // AppForm
    },

    props: ['loading'],

    data: () => ({}),

    methods: {
        openTel() {
            let number = '+7 (913) 919 84-34'
            let phone = number.split(' ').join('')
            // eslint-disable-next-line no-useless-escape
            phone = phone.replace(/\(|\)|\-/g, '')
            if (number.startsWith('8' || '7')) {
                phone = phone.split('')
                phone.splice(0, 1, '+7')
                phone = phone.join('')
            }
            window.location.href = 'tel:' + phone
        },

        openEmail(email) {
            window.location.href = `mailto:${email}`
        },

        openInst() {
            window.open(`https://instagram.com/_wolf_technology_?utm_medium=copy_link`, '_blank');
        },

        openMap() {
            window.open(`https://yandex.ru/maps/org/volf_tekhnolodzhi/125419001112/?from=mapframe&ll=82.997721%2C54.921029&z=17`, '_blank');
        }
    }
}

</script>

<style lang="sass">

.home-contacts
  margin-top: 48px

  &__map__wrap
    position: relative
    min-height: 500px
    height: 100%
    z-index: 1

  &__line__wrap
    position: absolute
    top: 12px
    right: 14px
    bottom: 16px
    left: -21px
    z-index: 0
    
  &__header
    position: relative
    z-index: 1

  &__title
    font-size: 42px
    text-transform: uppercase

  &__items
    display: flex
    justify-content: space-between
    margin-top: 10px
    padding-right: 20px
    font-size: 20px
    
    span
      line-height: 100%
      
    &__address
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 40px
        width: 30px
        margin-right: 5px
        
    &__phone
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 40px
        width: 30px
        margin-right: 5px
        
      img
        width: 100%
        height: auto
        
    &__email
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 40px
        width: 30px
        margin-right: 5px
        
    &__instagram
      display: flex
      align-items: center
      cursor: pointer
      
      div
        display: flex
        align-items: center
        height: 40px
        width: 30px
        margin-right: 5px
        
      img
        width: 100%
        height: auto
    
  &__form
    position: relative
    padding-bottom: 38px
    padding-right: 200px
    z-index: 1
    
    &__title
      margin-top: 48px
      margin-bottom: 18px
      font-size: 24px
      font-weight: bold
      
    &__btn
      margin-top: 20px
      padding: 10px 60px

@media (max-width: 2559.98px)

  .home-contacts
    margin-top: 36px
  
    &__line__wrap
      bottom: 12px
  
    &__title
      font-size: 38px
  
    &__items
      font-size: 16px
    
      &__address
        div
          width: 24px
      
      &__phone
        div
          width: 24px
      
      &__email
        div
          width: 24px
      
      &__instagram
        div
          width: 24px
  
    &__form
      padding-bottom: 24px
      padding-right: 160px
  
      &__title
        margin-top: 20px
        font-size: 22px
  
      &__btn
        margin-top: 10px

@media (max-width: 1919.98px)

  .home-contacts
    margin-top: 16px
    
    &__map__wrap
      min-height: 400px

    &__line__wrap
      bottom: 12px

    &__title
      font-size: 30px

    &__items
      flex-direction: column
      font-size: 18px
      
      &__address
        div
          width: 27px
      
      &__phone
        div
          width: 27px
      
      &__email
        div
          width: 27px
      
      &__instagram
        div
          width: 27px
      

    &__form
      padding-bottom: 0
      padding-right: 0

      &__title
        margin-top: 0
        font-size: 22px

      &__btn
        margin-top: 10px

@media (max-width: 1279.98px)
  
  .home-contacts
    
    &__map__wrap
      min-height: 350px
    
    &__title
      font-size: 28px

@media (max-width: 959.98px)
  
  .home-contacts
    
    &__title
      font-size: 26px
      
    &__map
      &__wrap
        height: 300px

@media (max-width: 599.98px)
  
  .home-contacts
    
    &__title
      font-size: 22px


</style>